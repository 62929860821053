<template>
  <div class="home-cont" style="overflow: hidden">
    <navs :para="false" :active="0"></navs>
    <img class="fbg" src="../assets\images\jj/fbg.jpg" alt="" />
    <div class="swiper-block">
      <swiper style="width: 100%" ref="mySwiper" :options="swiperOptions">
        <swiper-slide>
           <img class="swiperimg" src="../assets/image/ch.png" />
        </swiper-slide>
        <swiper-slide>
          <img class="swiperimg" src="../assets/image/banner@2x.png" />
          <img class="banner-center" src="../assets/image/字@2x.png" />
        </swiper-slide>
        <swiper-slide>
          <img class="swiperimg" src="../assets/image/ch.png" />
        </swiper-slide>
        <swiper-slide>
          <img class="swiperimg" src="../assets/image/banner@2x.png" />
          <img class="banner-center" src="../assets/image/字@2x.png" />
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>
    <div id="preloadedImages" style="width:0; height:0"></div>

    <div class="title" style="padding-bottom: 80px; padding-top: 140px">
      <p>关于我们</p>
    </div>
    <div class="about_text" style="font-size: 18px;">
      <div>SynSense时识科技是全球领先的类脑智能与应用解决方案提供商</div>
      <div>以苏黎世大学类脑科学为基础，聚焦边缘计算，提供横跨感知与计算的类脑智能应用解决方案</div>
      <div>为人工智能构建认知生态体系，推动实现万物智联</div>
    </div>
    <!-- <div class="tr"></div> -->
    <div class="title" style="padding-bottom: 80px;margin-top: 340px;padding-top: 140px;">
      <p>应用领域</p>
    </div>
    <div class="s_out">
      <div class="success">
        <swiper :options="swiperOptions3">
            <swiper-slide>
            <div class="img">
              <img src="../assets/images/jj/01-1.png" alt="" />
            </div>
            <div class="text">
              <div>
                对准机器人应用在感知、功耗、实时性、智能化等方面的性能不足及功能限制。
                <br />
                SynSense时识科技专注于机器人相关多模态感知芯片、主控芯片等创新研发，用类脑智能推动机器人在感知边端实现视觉、听觉、触觉、嗅觉。
              </div>
              <div class="item">
                <div>
                  <img src="../assets/images/jj/dgh@2x.png" alt="" />
                  <div>
                    <div class="t4">超低功耗</div>
                  </div>
                </div>
                <div>
                  <img src="../assets/images/jj/dyc@2x.png" alt="" />
                  <div>
                    <div class="t4">超低延迟</div>
                  </div>
                </div>
              </div>

            </div>
          </swiper-slide>
            <swiper-slide>

            <div class="text" style="padding-right:81px; padding-left:23px;">
              <div>
                SynSense时识科技事件驱动的神经形态处理器，运用全球领先的动态视觉处理技术，开发面部或运动物体检测、定位、捕捉和监视等功能。
                <br />
                让事件驱动、超低功耗、永远在线、保护隐私的智能安防系统成为可能。
              </div>
              <div class="item">
                <div>
                  <img src="../assets/images/jj/dgh@2x.png" alt="" />
                  <div>
                    <div class="t4">超低功耗</div>
                  </div>
                </div>
                <div>
                  <img src="../assets/images/jj/ysaq.png" alt="" />
                  <div>
                    <div class="t4">隐私安全</div>
                  </div>
                </div>
              </div>

            </div>
            <div class="img">
              <img src="../assets\images\jj/01-2.png" alt="" />
            </div>
        </swiper-slide>

            <swiper-slide>
            <div class="img">
              <img src="../assets/images/jj/01-3.png" alt="" />
            </div>
            <div class="text">
              <div>
                康养系统及产品存在操作繁琐、耗电高、不够智慧、私密场所隐私等问题。
                <br />
                SynSense时识科技类脑智能解决方案，发挥毫瓦级动态视觉交互优势，实现跌倒监测、实时监测，赋能更聪明、更安全、更贴心的智慧康养系统。
              </div>
              <div class="item">
                <div>
                  <img src="../assets/images/jj/dyc@2x.png" alt="" />
                  <div>
                    <div class="t4">超低延迟</div>
                  </div>
                </div>
                <div>
                  <img src="../assets/images/jj/dgh@2x.png" alt="" />
                  <div>
                    <div class="t4">超低功耗</div>
                  </div>
                </div>
                <div>
                  <img src="../assets/images/jj/ysaq.png" alt="" />
                  <div>
                    <div class="t4">隐私安全</div>
                  </div>
                </div>
              </div>

            </div>
          </swiper-slide>

          <swiper-slide>
            <div class="text"  style="padding-right:81px; padding-left:23px;">
              <div>
                现有动物可穿戴设备普遍存在集成度低、更换成本高、监测功能单一、占用资源等问题。
                <br />
                SynSense时识科技端侧智能动物可穿戴解决方案，具备超低功耗、低成本、高集成度、小体积优势，以强大的生物信号识别、行为监测功能，精准回应智慧养殖应用需求。
              </div>
              <div class="item">
                <div>
                  <img src="../assets/images/jj/dgh@2x.png" alt="" />
                  <div>
                    <div class="t4">超低功耗</div>
                  </div>
                </div>
                <div>
                  <img src="../assets/images/jj/dcb.png" alt="" />
                  <div>
                    <div class="t4">低成本</div>
                  </div>
                </div>
                <div>
                  <img src="../assets/images/jj/dyc@2x.png" alt="" />
                  <div>
                    <div class="t4">超低延迟</div>
                  </div>
                </div>
              </div>

            </div>
            <div class="img">
              <img src="../assets\images\jj/01-4.png" alt="" />
            </div>
        </swiper-slide>


        <swiper-slide>
            <div class="img">
              <img src="../assets/images/jj/01-5.png" alt="" />
            </div>
            <div class="text">
              <div>
                针对传统解决方案功耗高、成本高、反应延迟、暴露隐私等痛点，SynSense时识科技“感算一体”的纯端类脑解决方案将在降低成本功耗、保护隐私的基础上，实现驾驶行为分析，基于动态视觉感知及计算的高速避障，保护生命安全。
              </div>
              <div class="item">
                <div>
                  <img src="../assets/images/jj/dyc@2x.png" alt="" />
                  <div>
                    <div class="t4">超低延迟</div>
                  </div>
                </div>
                <div>
                  <img src="../assets/images/jj/ysaq.png" alt="" />
                  <div>
                    <div class="t4">隐私安全</div>
                  </div>
                </div>
                <div>
                  <img src="../assets/images/jj/dcb.png" alt="" />
                  <div>
                    <div class="t4">低成本</div>
                  </div>
                </div>

              </div>

            </div>
          </swiper-slide>


          <swiper-slide>
            <div class="text"  style="padding-right:81px; padding-left:23px;">
              <div>
                面对传统智能玩具成本偏高、灵敏度低、精准度低、智能交互程度低、暴露隐私等弱点，用类脑智能技术，以低成本实现毫瓦级超低功耗、毫秒级超低延迟的全仿生视觉、语音实时交互。
                <br />
                让玩具更聪明，灵敏回应孩童的好奇与情感。
              </div>
              <div class="item">
                <div>
                  <img src="../assets/images/jj/dcb.png" alt="" />
                  <div>
                    <div class="t4">低成本</div>
                  </div>
                </div>
                <div>
                  <img src="../assets/images/jj/dyc@2x.png" alt="" />
                  <div>
                    <div class="t4">超低延迟</div>
                  </div>
                </div>
                <div>
                  <img src="../assets/images/jj/ysaq.png" alt="" />
                  <div>
                    <div class="t4">隐私安全</div>
                  </div>
                </div>
              </div>

            </div>
            <div class="img">
              <img src="../assets/images/jj/01-6.png" alt="" />
            </div>
        </swiper-slide>

        <swiper-slide>
            <div class="img">
              <img src="../assets/images/jj/01-7.png" alt="" />
            </div>
            <div class="text">
              <div>
                科技为人类创造更便利、更舒适、更安全的生活空间。
                <br />
                SynSense时识科技将超低功耗、永远在线、保护隐私的智能识别、实时交互功能深度融入生活场景，用类脑智能关照个人日常，诠释更加人性化的家居空间。
              </div>
              <div class="item">
                <div>
                  <img src="../assets/images/jj/dgh@2x.png" alt="" />
                  <div>
                    <div class="t4">超低功耗</div>
                  </div>
                </div>
                <div>
                  <img src="../assets/images/jj/ysaq.png" alt="" />
                  <div>
                    <div class="t4">隐私安全</div>
                  </div>
                </div>

              </div>

            </div>
          </swiper-slide>


        </swiper>
      </div>
    </div>

    <div class="title" style="padding-bottom: 80px">
      <p>解决方案</p>
    </div>
    <ul class="features-list" style="z-index:10;">
      <li class='item1'>
        <!-- <img class="basemap" src="../assets/images/home/home1.png" alt="" /> -->
        <!-- <img class="basemap" v-else src="../assets/images/home/home1-1.png" alt="" /> -->
        <div class="mask">
          <p>智能视觉处理</p>
          <p>
            SynSense时识科技开发事件驱动的神经形态处理器，用于实时视觉处理。SynSense时识科技低功耗
          </p>
        </div>
        <div class="search-block" @click="jump">
<!--          <img class="searchIcon " src="../assets/image/搜索@2x.png" alt="" />-->
          了解详情
        </div>
      </li>
      <li class='item2'>
        <!-- <img class="basemap" src="../assets/images/home/home1-2.png" alt="" /> -->
        <div class="mask">
          <p>语音信号处理</p>
          <p>
            SynSense时识科技开发基于脉冲神经网络（SNN）的低功耗的语音识别与口令识别。
          </p>
        </div>
        <div class="search-block" @click="jump">
<!--          <img class="searchIcon" src="../assets/image/搜索@2x.png" alt="" />-->
          了解详情
        </div>

      </li>
      <!-- <li>
        <img class="basemap" src="../assets/image/10@2x.png" alt="" />
        <div class="mask">
          <p>智能服务助手</p>
          <p>
            SynSense时识科技开发基于脉冲神经网络（SNN）的低功耗的语音识别与口令识别。
          </p>
        </div>
        <div class="search-block">
          <img class="searchIcon" src="../assets/image/搜索@2x.png" alt="" />
        </div>
      </li> -->
      <li  class='item3'>
        <!-- <img class="basemap" src="../assets/images/home/home1-3.png" alt="" /> -->
        <div class="mask">
          <p>智能可穿戴设备</p>
          <p>SynSense时识科技开发用于储层神经形态计算的硬件解决方案。</p>
        </div>
        <div class="search-block" @click="jump">
<!--          <img class="searchIcon" src="../assets/image/搜索@2x.png" alt="" />-->
<!--          <div class="btnmore">-->
<!--            <span>查看全部项目</span>-->
<!--          </div>-->
          查看详情
        </div>
      </li>
    </ul>
<!--    <div class="btnmore_out">-->
<!--      <div class="btnmore">-->
<!--        <span>查看全部项目</span>-->
<!--      </div>-->
<!--    </div>-->

    <div class="news-block">
      <div class="news-block-cont">
        <div class="title" style="text-align: center">
          <p>最新资讯</p>
        </div>
        <ul class="news-list clearfix" ref="di">
          <swiper style="width: 100%" ref="mySwiper5" :options="swiperOptions5">
            <swiper-slide>
              <li @click="goDetial(17)">
                <div class="img-block">
                  <img
                    src="../assets/image/zx1.png"
                    alt=""
                  />
                  <div></div>
                </div>
                <p>SynSense时识科技获全国颠覆性技术创新大赛优胜项目</p>
                <p>2022.1.4</p>
              </li>
            </swiper-slide>

            <swiper-slide>
              <li @click="goDetial(18)">
                <div class="img-block">
                  <img src="../assets/image/zx2.jpg" alt="" />
                  <div></div>
                </div>
                <p>
                  2021 Great Tech Awards 正式揭晓，SynSense时识科技获“杰出科技创新奖”</p>
                <p>2022.1.4</p>
              </li>
            </swiper-slide>

            <swiper-slide>
              <li @click="goDetial(2)">
                <div class="img-block">
                  <img
                    src="../assets/image/zx3.jpg"
                    alt=""
                  />
                  <div></div>
                </div>
                <p>

                  第六届中国国际人工智能领袖峰会年度评选获奖名单出炉，SynSense时识科技获重磅大奖
                </p>
                <p>2021.12.16</p>
              </li>
            </swiper-slide>
          </swiper>
        </ul>
        <!-- <div class="dian">
          <img src="../assets/images/dian/向左.png" @click="diandian('l')">
          <img src="../assets/images/dian/向左.png"  @click="diandian('r')" style="transform: rotate(180deg);">
        </div> -->
        <!-- <div class="news-list sj-news-list">
          <img src="../assets/image/新闻01@2x.png">
          <img src="../assets/image/新闻02@2x.png">
          <img src="../assets/image/新闻03@2x.png">
        </div> -->
      </div>
    </div>
    <div class="Partner">
      <div class="title">
        <p>合作伙伴</p>
      </div>
      <!-- <div class="imgs">
        <img src="../assets/image/01-彩色@2x.png" alt="">
        <img src="../assets/image/02-彩色@2x.png" alt="">
        <img src="../assets/image/03-彩色@2x.png" alt="">
        <img src="../assets/image/04-彩色@2x.png" alt="">
      </div> -->
      <div class="imgs">
        <swiper style="width: 100%" ref="mySwiper" :options="swiperOptions6">
          <swiper-slide>
                <img src="../assets/images/cooper/11-1@2x.png" alt="" />
          </swiper-slide>
          <swiper-slide>
              <img src="../assets/images/cooper/11-2@2x.png" alt="" />
          </swiper-slide>
          <swiper-slide>
            <img src="../assets/images/cooper/11-3@2x.png" alt="" />
          </swiper-slide>
          <swiper-slide>
            <img src="../assets/images/cooper/11-4@2x.png" alt="" />
          </swiper-slide>
          <swiper-slide>
            <img src="../assets/images/cooper/11-5@2x.png" alt="" />
          </swiper-slide>
          <swiper-slide>
            <img src="../assets/images/cooper/11-6@2x.png" alt="" />
          </swiper-slide>
          <swiper-slide>
            <img src="../assets/images/cooper/11-7@2x.png" alt="" />
          </swiper-slide>
          <swiper-slide>
            <img src="../assets/images/cooper/11-8@2x.png" alt="" />
          </swiper-slide>
          <swiper-slide>
            <img src="../assets/images/cooper/11-9@2x.png" alt="" />
          </swiper-slide>
          <swiper-slide>
            <img src="../assets/images/cooper/11-10@2x.png" alt="" />
          </swiper-slide>
        </swiper>
      </div>
    </div>
    <div class="achievement-block-para">
      <div class="achievement-block">
        <div class="wz" style="margin-top:160px;">
          <div>订阅最新成果</div>
        </div>
        <div class="sr">
          <input type="text" placeholder="请输入您的邮箱" />
          <img src="../assets/image/发送@2x.png" alt="" />
        </div>
      </div>
    </div>

    <bases></bases>
  </div>
</template>
<script>
import navs from "@/components/navs.vue";
import bases from "@/components/bases.vue";
export default {
  name: "Home",
  components: {
    navs,
    bases,
  },
  data() {
    return {
      dq:0,
      img1:false,
      tablelistIndex: 0,
      swiperOptions: {
        loop: true, // 循环切换

        // grabCursor: true, //设置为true时，鼠标覆盖Swiper时指针会变成手掌形状，拖动时指针会变成抓手形状
        // setWrapperSize: true, //Swiper使用flexbox布局(display: flex)，
        // 开启这个设定会在Wrapper上添加等于slides相加的宽或高，
        // 在对flexbox布局的支持不是很好的浏览器中可能需要用到。
        autoHeight: false, // 自动高度。设置为true时，wrapper和container会随着当前slide的高度而发生变化。
        autoplay: {
          disableOnInteraction: false,
        }, // 可选选项，自动滑动
        observeParents: false, // 当改变swiper的样式（例如隐藏/显示）或者修改swiper的子元素时，自动初始化swiper
        pagination: {
          el: ".swiper-pagination",
          clickable :true,
        },
        navigation: {
          nextEl: ".rightIcon",
          prevEl: ".leftIcon",
        },
        speed: 300,
        // on: {
        //   // 监听滑动切换事件，返回swiper对象
        //   slideChange: () => {
        //     const i = this.swiper.activeIndex
        //     this.changepicture(i)
        //   }
        // }
        // Some Swiper option/callback...
      },

      swiperOptions3:{
        loop: true, // 循环切换
        // grabCursor: true, //设置为true时，鼠标覆盖Swiper时指针会变成手掌形状，拖动时指针会变成抓手形状
        // setWrapperSize: true, //Swiper使用flexbox布局(display: flex)，
        // 开启这个设定会在Wrapper上添加等于slides相加的宽或高，
        // 在对flexbox布局的支持不是很好的浏览器中可能需要用到。
        autoHeight: false, // 自动高度。设置为true时，wrapper和container会随着当前slide的高度而发生变化。
        autoplay: true, // 可选选项，自动滑动
        observeParents: false, // 当改变swiper的样式（例如隐藏/显示）或者修改swiper的子元素时，自动初始化swiper
        // pagination: {
        //   el: ".swiper-pagination",
        // },
        navigation: {
          nextEl: ".rightIcon",
          prevEl: ".leftIcon",
        },
        speed: 300,
      },
      swiperOptions1: {
        slidesPerView: 4,
        loop: true, // 循环切换
        // grabCursor: true, //设置为true时，鼠标覆盖Swiper时指针会变成手掌形状，拖动时指针会变成抓手形状
        // setWrapperSize: true, //Swiper使用flexbox布局(display: flex)，
        // 开启这个设定会在Wrapper上添加等于slides相加的宽或高，
        // 在对flexbox布局的支持不是很好的浏览器中可能需要用到。
        autoHeight: false, // 自动高度。设置为true时，wrapper和container会随着当前slide的高度而发生变化。
        autoplay: true, // 可选选项，自动滑动
        observeParents: false, // 当改变swiper的样式（例如隐藏/显示）或者修改swiper的子元素时，自动初始化swiper
        speed: 1000,
      },
      swiperOptions5: {
        slidesPerView: 3,
        loop: true, // 循环切换
        // grabCursor: true, //设置为true时，鼠标覆盖Swiper时指针会变成手掌形状，拖动时指针会变成抓手形状
        // setWrapperSize: true, //Swiper使用flexbox布局(display: flex)，
        // 开启这个设定会在Wrapper上添加等于slides相加的宽或高，
        // 在对flexbox布局的支持不是很好的浏览器中可能需要用到。
        autoHeight: false, // 自动高度。设置为true时，wrapper和container会随着当前slide的高度而发生变化。
        autoplay: true, // 可选选项，自动滑动
        observeParents: false, // 当改变swiper的样式（例如隐藏/显示）或者修改swiper的子元素时，自动初始化swiper
        speed: 1000,
      },
      swiperOptions6: {
        slidesPerView: 4,
        loop: true, // 循环切换
        // grabCursor: true, //设置为true时，鼠标覆盖Swiper时指针会变成手掌形状，拖动时指针会变成抓手形状
        // setWrapperSize: true, //Swiper使用flexbox布局(display: flex)，
        // 开启这个设定会在Wrapper上添加等于slides相加的宽或高，
        // 在对flexbox布局的支持不是很好的浏览器中可能需要用到。
        autoHeight: false, // 自动高度。设置为true时，wrapper和container会随着当前slide的高度而发生变化。
        autoplay: true, // 可选选项，自动滑动
        observeParents: false, // 当改变swiper的样式（例如隐藏/显示）或者修改swiper的子元素时，自动初始化swiper
        speed: 1000,
      },
    };
  },
  mounted() {
    window.addEventListener("scroll", this.scrollToTop);

    // const that = this;
    // window.onresize = () => (() => {
    //   that.setBGblock();
    // })();
    // this.setBGblock();
  },
  methods: {
    diandian(i){
      if(i=='l'){
        if(this.dq<0){
          this.dq+=30
          document.getElementsByClassName('news-list')[0].style.transform = 'translateX('+this.dq+'%)'

          console.log(this.dq)
        }
        console.log('向左')

      }else{
        if(this.dq>-30){
          this.dq-=30
          document.getElementsByClassName('news-list')[0].style.transform =  'translateX('+this.dq+'%)'

          console.log(this.dq)
        }
        console.log('向右')
      }
    },
    jump() {
      console.log('@@@jump');
      this.$router.push({
        path:'/solutions',
      })
    },
    scrollToTop() {
      var hash = window.location.hash;
      if (hash.split("#/").length <= 1 || hash.split("#/")[1] == "") {
        const scrollTop =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop;
        // if (scrollTop >= 30) {
        //   this.isfixed = true;
        // } else {
        //   this.isfixed = false;
        // }

        if(document.getElementsByClassName("about-us-img").length>0){
          let top = document.getElementsByClassName("about-us-img")[0].offsetTop - 95;
            let top2 = top - 300;
          if (scrollTop > top2 && scrollTop <= top) {
            let num = top - scrollTop;
            document.getElementsByClassName("about-us-img")[0].style.transform =
              "scale(" + Number((1 + (num / 300) * 0.3).toFixed(2)) + ")";
            // document.getElementsByClassName('cont_img')[0].style.opacity = 1-(num / 300);
          }
           if (scrollTop >= top) {
              document.getElementsByClassName("about-us-img")[0].style.transform =
                "scale(1)";
              // document.getElementsByClassName('cont_img')[0].style.opacity = 1;
              document.getElementsByClassName("cont_img")[0].style.transform =
                "translateY(0px) scale(1)";
              document.getElementsByClassName("cont_img")[1].style.transform =
                "translateY(0px) scale(1)";
            }
        }


        // let yy = document.getElementsByClassName("yyly")[0].offsetTop - 900;
        // let yy2 = yy + 1000 + 900;
        // let yynum = yy2 - scrollTop;
        // if (scrollTop >= yy && scrollTop <= yy2) {
        //   document.getElementsByClassName(
        //     "yylyfiexd"
        //   )[0].style.backgroundPositionY =
        //     Number(((yynum / 1000) * -300).toFixed(2)) + "px";
        //   // console.log(Number((((yynum / 3050)*50)+20).toFixed(2)))
        // }
        // let yyly = document.getElementsByClassName("yyly2")[0].offsetTop - 900;
        // let yyly2 = yyly + 1200;
        // let yylynum = yyly2 - scrollTop;
        // if (scrollTop >= yyly && scrollTop <= yyly2) {
        //   document.getElementsByClassName(
        //     "yylyfiexd2"
        //   )[0].style.backgroundPositionY =
        //     Number(((yylynum / 1200) * -600).toFixed(2)) + "px";
        //   // console.log(Number((((yynum / 3050)*50)+20).toFixed(2)))
        // }

        // let wztop =
        //   document.getElementsByClassName("about-us-img")[0].offsetTop - 95;
        // let opc = document.getElementsByClassName("texts")[0].offsetTop - 95;
        // let wztop2 = wztop - 500;
        // if (scrollTop > wztop2 && scrollTop >= opc) {
        //   document.getElementsByClassName("cont_img")[0].style.opacity = 1;
        //   document.getElementsByClassName("cont_img")[1].style.opacity = 0;
        // } else {
        //   document.getElementsByClassName("cont_img")[0].style.opacity = 0;
        //   document.getElementsByClassName("cont_img")[1].style.opacity = 0;
        // }
        // if (scrollTop > wztop2 && scrollTop <= wztop) {
        //   let num = wztop - scrollTop;
        //   let dax = Number((1 - num / 500).toFixed(2));
        //   let opc = Number((1 - num / 500).toFixed(2));
        //   let opc1 = Number((num / 500).toFixed(2));
        //   if (dax <= 0.4) {
        //     dax = 0.4;
        //   }
        //   document.getElementsByClassName("cont_img")[0].style.transform =
        //     "translateY(" + Number(-num) + "px) scale(" + dax + ")";
        //   document.getElementsByClassName("cont_img")[1].style.transform =
        //     "translateY(" + Number(-num) + "px) scale(" + dax + ")";
        //   document.getElementsByClassName("cont_img")[0].style.opacity = opc;
        //   document.getElementsByClassName("cont_img")[1].style.opacity = opc1;
        //   // console.log( 'translateY('+Number(-num)+'px) scale('+dax+')')
        // }
      }
    },
    setBGblock() {
      this.$refs.bgBlcok.style.width = `${
        380 + this.$refs.aboutUsCont.getBoundingClientRect().left
      }px`;
    },
    goDetial(item_id){
      console.log('详情id: ',item_id);
      this.$router.push('mediaDetail?id=' + item_id)
      
    }
  },
};
</script>

<style lang="less" scoped>

.dian{
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  margin-top: 30px;
  img{
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
  img+img{
    margin-left: 20px;
  }
}
.item1{
  background-image: url('../assets/images/home/home11-1.png');
  background-size: 100% 100%;
}
.item1:hover{
  background-image: url('../assets/images/home/home11.png');
  background-size: 100% 100%;
}
.item2{
  background-image: url('../assets/images/home/home11-2.png');
  background-size: 100% 100%;
}
.item2:hover{
  background-image: url('../assets/images/home/home12.png');
  background-size: 100% 100%;
}
.item3{
  background-image: url('../assets/images/home/home11-3.png');
  background-size: 100% 100%;
}
.item3:hover{
  background-image: url('../assets/images/home/home13.png');
  background-size: 100% 100%;
}
.success {
  width: 960px;
  margin: 0 auto;
  padding-bottom: 140px;
  .swiper-wrapper > div {
    width: 100% !important;
    height: 260px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    text-align: center;
    .text {
      flex: 1;
      display:flex;
      flex-flow:column;
      text-align:left;
      justify-content: space-between;
      padding-left:81px;
      padding-right:43px;
      padding-top:14px;
      width: 375px;
      font-size: 14px;
      font-weight: 300;
      line-height: 24px;
      color: #003F52;
      opacity: 1;
      .head {
        font-size: 40px;
        font-weight: bold;
        color: #00242a;
        margin-top: 55px;
        margin-bottom: 66px;
        text-transform:uppercase;
      }
      .item {
        display: flex;
        justify-content: space-around;
        font-size: 14px;
        font-weight: 300;
        line-height: 20px;
        height:70px;
        color: #94c47e;
        .t1 {
          font-size: 20px;
          font-weight: bold;
          color: #708065;
        }
        .t11 {
          font-size: 14px;
          font-weight: 300;
          color: #94c47e;
        }
        .t2 {
          margin-top:14px;
          height: 16px;
          font-size: 16px;
          font-weight: 400;
          line-height: 0px;
          color: #00B7D0;
          opacity: 1;
        }
        .t22 {
          font-size: 14px;
          font-weight: 300;
          color: #cec9a2;
        }
        .t3 {
          font-size: 20px;
          font-weight: bold;
          color: #b85546;
        }
        .t33 {
          font-size: 14px;
          font-weight: 300;
          color: #f37442;
        }
        .t4 {
          // margin-top:14px;
          height: 16px;
          font-size: 16px;
          font-weight: 400;
          line-height: 0px;
          color: #00B7D0;
          opacity: 1;
        }
        .t44 {
          font-size: 14px;
          font-weight: 300;
          color: #00a0da;
        }
        > div {
          display: flex;
          flex-flow: column;
          height:70px;
          justify-content: space-between;
          align-items: center;
          > div:nth-child(2) {
            padding-top: 10px;
          }
        }
        img {
          width: 40px;
          height: 40px;
        }
      }
    }
    .img {
      width: 460px;
      height: 260px;
      img {
        width: 460px;
        height: 260px;
      }
    }
  }
}
.about_text {
height: 106px;
  font-size: 14px;
  font-weight: 300;
  line-height: 38px;
  color: #003F52;
  text-align: center;
  opacity: 1;
  padding-bottom:123px;
}
.dx_2 {
  animation: dx 2s linear;
}
.opc_3 {
  animation: opc 3s linear;
}

@keyframes dx {
  0% {
    transform: scale(1.5);
    opacity: 0.8;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes opc {
  0% {
    opacity: 0;
  }
  60% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.swiper-block {
  position: relative;
  .banner-center {
    width: 1200px;
    height: 267px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -600px;
    margin-top: -133.5px;
    z-index: 10;
  }
  .swiperimg {
    width: 100%;
  }
  .swiper-pagination {
    width: 100%;
    bottom: 15%;
    /deep/.swiper-pagination-bullet {
      width: 26px;
      height: 26px;
      background-color: #ffffff;
      opacity: 1;
      margin: 0px 29px;
    }
    /deep/.swiper-pagination-bullet-active {
      background-color: #0086cd;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        width: 40px;
        height: 40px;
        border-radius: 100%;
        background-color: rgba(0, 0, 0, 0);
        box-sizing: border-box;
        border: 4px solid #0086cd;
        transform: translateX(-50%) translateY(-50%);
        left: 50%;
        top: 50%;
      }
    }
  }
}
.title {
  text-align: center;
  p:nth-child(1) {
    margin-bottom: 5px;
    height: 36px;
    font-size: 36px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    text-align: center;
    color: #00B7D0;
    opacity: 1;
  }
  p:nth-child(2) {
    color: #00242a;
    font-size: 40px;
    font-weight: bold;
  }
}
.about-us {
  width: 100%;
  background-color: #fff;
  height: auto;
  padding-bottom: 122px;
  overflow: hidden;
  .about-us-cont {
    width: 1200px;
    height: 100%;
    margin: auto;
    display: flex;
    position: relative;
    .about-us-left {
      width: 100%;
      // padding-right: 190px;
      box-sizing: border-box;
      color: #17181a;
      font-size: 18px;
      text-align: center;
      .title {
        text-align: center;
        margin-top: 122px;
        letter-spacing: 5px;
      }
      .texts {
        width: 630px;
        height: 180px;
        font-size: 30px;
        font-weight: 500;
        line-height: 60px;
        color: #17181a;
        opacity: 1;
        margin: 10px auto 0;
      }
      .text1 {
        margin-top: 38px;
      }
      .text2 {
        margin-top: 25px;
      }
      .img1 {
        width: 180px;
        margin-top: 139px;
      }
      .img2 {
        width: 477px;
        margin-top: 69px;
      }
    }
    .bg-blcok {
      position: absolute;
      width: 380px;
      height: 100%;
      left: 820px;
      background-color: rgba(0, 0, 0, 0.8);
      img {
        width: 57%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        left: 50%;
      }
    }
  }
}
.stories-block {
  width: 1200px;
  height: auto;
  margin: auto;
  .title {
    margin-top: 128px;
  }
  .tablelist {
    display: flex;
    justify-content: space-between;
    margin-top: 67px;
    li {
      width: 290px;
      cursor: pointer;
      img {
        width: 100%;
      }
    }
  }
  .cont-img {
    width: 1180px;
    margin: auto;
    margin-top: 91px;
  }
  .iconList {
    display: flex;
    justify-content: space-between;
    margin-top: 70px;
    li {
      text-align: center;
      width: 220px;
      cursor: pointer;
      color: #00242a;
      > div {
        width: 220px;
        height: 220px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 4px dashed #505156;
        border-radius: 100%;
        img {
          width: 90px;
        }
        .img1 {
          display: block;
        }
        .img2 {
          display: none;
        }
      }
      p:nth-child(2) {
        font-size: 38px;
        margin-top: 38px;
        font-weight: bold;
      }
      p:nth-child(3) {
        font-size: 30px;
        margin-top: 5px;
      }
      // &:hover{
      //   >div{
      //     border:none;
      //     background-color: #0086CD;
      //     .img1{
      //       display: none;
      //     }
      //     .img2{
      //       display: block;
      //     }
      //   }
      // }
    }
    .chooseLi {
      > div {
        border: none;
        background-color: #0086cd;
        .img1 {
          display: none;
        }
        .img2 {
          display: block;
        }
      }
    }
  }
}
.features-list {
  height: 700px;
  background:#4F4F4F;
  display: flex;
  li {
    flex: 1;
    position: relative;
    .basemap {
      width: 100%;
    }
    .mask {
      width: 100%;
      height: 100%;
      position: absolute;
      background-color: rgba(0, 0, 0, 0.5);
      left: 0px;
      top: 0px;
      color: #ffffff;
      opacity: 1;
      transition: all 1s;
      p {
        margin: auto;
      }
      p:nth-child(1) {
        width: 292px;
        max-width: 100%;
        // height: 84px;
        text-align: center;
        font-size: 26px;
        margin-top: 290px;
      }
      p:nth-child(2) {
        width: 383px;
        max-width: 90%;
        text-align: center;
        font-size: 16px;
        margin-top: 40px;
        font-weight: 300;
        line-height: 28px;
        color: #FFFFFF;
        opacity: 1;
      }
    }
    .search-block {
      width: 260px;
      height: 66px;
      line-height:66px;
      font-family: "Source Han Sans CN";
      font-size:26px;
      color: #FFFFFF;;
      border-radius: 66px;
      background-color: #00b7d0;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      opacity: 0;
      img {
        width: 50px;
      }
    }
    &:hover {
      .mask {
        opacity: 0;
      }
      .search-block {
        transition: all 1s;
        opacity: 1;
      }
    }
  }
}
.btnmore_out{
  width: 100%;
  height: auto;
  background-color: #fff;
  margin-top: -70px;
  padding-top: 1px;
}
.btnmore {
  width: 208px;
  height: 54px;
  border: 2px solid #0086cd;
  border-radius: 27px;
  font-size: 22px;
  line-height: 54px;
  text-align: center;
  color: #0086cd;
  margin: 71px auto;
  margin-bottom: 0px;
  cursor: pointer;
  position: relative;
  background-color: transparent;
  overflow: hidden;
  box-sizing: border-box;
  span {
    position: relative;
    z-index: 10;
  }
}
.btnmore:hover {
  border: none;
  line-height: 56px;
  color: #fff;
  animation: 1s color1 linear;
}
.btnmore:hover::before {
  content: "";
  width: 120%;
  height: 100%;
  position: absolute;
  left: -10%;
  top: 0;
  background-color: #0086cd;
  z-index: -1px;
  animation: 1s lrs linear;
}
.btnmore:hover::after {
  content: "";
  width: 120%;
  height: 100%;
  position: absolute;
  left: 110%;
  top: 0;
  background-color: #0086cd;
  z-index: -1px;
  animation: 1s lrs2 linear;
}
@keyframes lrs {
  0% {
    left: -120%;
  }
  100% {
    left: -10%;
  }
}
@keyframes lrs2 {
  0% {
    left: 100%;
  }
  100% {
    left: -10%;
  }
}
@keyframes color1 {
  0% {
    color: #0086cd;
  }
  100% {
    color: #ffffff;
  }
}
.fbg {
  position: fixed;
  z-index: -1;
  width: 100%;
  height: 100%;
}
.s_out {
  width: 100%;
  height: auto;
  background-color: #fff;
}
.tr {
  width: 100%;
  height: 340px;
  background-color: transparent;
}
.home-cont {
  > div {
    position: relative;
    z-index: 10;
    background-color: #fff;
  }
}
.news-block {
  width: 100%;
  height: auto;
  margin: auto;
  // margin-top: 120px;
  padding-top: 140px;
  padding-bottom: 153px;
  background: #ffffff;
  overflow: hidden;
  .news-block-cont {
    width: 960px;
    margin: auto;
    .title {
      text-align: left;
    }
    .news-list {
      display: flex;
      position: relative;
      left: 0px;
      margin-top: 80px;
      justify-content: space-between;
      li {
        width: 310px;
        height: 320px;
        margin-right: 15px;
        background-color: red;
        flex-shrink: 0;
        border-radius: 10px;
        background-color: #f8f8f8;
        overflow: hidden;
        display: inline-block;
        .img-block {
          position: relative;
          width: 100%;
          height: 180px;
          img {
            width: 100%;
            height: 180px;
          }
          div {
            position: absolute;
            left: 0px;
            top: 0px;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.5);
            opacity: 1;
          }
        }
        &:hover {
          cursor: pointer;
          background-color: #ffffff;
          box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.16);
          .img-block {
            div {
              opacity: 0;
            }
          }
        }
        p {
          padding: 20px 20px;
          padding-bottom: 0px;
        }
        p:nth-child(2) {
          // color: #1d1d1f;
          // margin-top: 25px;
          // word-break: keep-all; /* 不换行 */
          // white-space: nowrap; /* 不换行 */
          // overflow: hidden;
          // text-overflow: ellipsis;

          // width: 262px;
          font-size: 14px;
          font-weight: 400;
          line-height: 26px;
          color: #1D1D1F;
          opacity: 1;
          display:-webkit-box;
          overflow: hidden;
          text-overflow: ellipsis;
          word-wrap: break-word;
          white-space: normal !important;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        p:nth-child(3) {
          color: #6E6E73;
          font-size: 14px;
          // margin-top: 42px;
        }
      }
    }
  }
}
.Partner {
  .swiper-slide {
    height:126px;
  }
  width: 100%;
  height: 380px;
  background: #fff;
  padding-top: 140px;
  box-sizing: border-box;
  margin-top: 340px;
  .imgs {
    width: 960px;
    margin: auto;
    margin-top: 80px;
    height: 126px;
    img {
      width: 220px;
      height:114px;
      background: #FFFFFF;
      margin-top:6px;
      margin-left:10px;
      opacity: 1;
      border-radius: 8px;
      box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
      -moz-box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
      -webkit-box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
    }

  }
}
.achievement-block-para {
  width: 100%;
  height: 326px;
  background: linear-gradient(180deg, #ffffff 0%, #e6e6e6 100%);
}
.achievement-block {
  width: 960px;
  height: auto;
  margin: 0 auto;
  // background-image: url('../assets/image/订阅-底@2x.png');
  // background-repeat: no-repeat;
  // background-size: 100% 100%;
  // padding-top: 114px;
  padding-bottom: 140px;
  box-sizing: border-box;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  > .sr {
    width: 610px;
    height: 62px;
    // padding: 4px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    border-radius: 8px;
    input {
      flex: 1;
      height: 54px;
      line-height: 54px;
      padding: 0px 23px;
      border: none;
      outline: none;
      font-size:18px;

    }
    input::placeholder {
      color: #00B7D0;
    }
    img {
      width: 150px;
      height: 54px;
      margin-right:4px;
    }
  }
  > .wz {
    > div:first-child {
      width: auto;
      height: auto;
      font-size: 20px;
      font-weight: 400;
      line-height: 30px;
      color: #00242a;
      letter-spacing: 5px;
      opacity: 1;
    }
    > div:last-child {
      // font-size: 40px;
      // font-weight: bold;
      // line-height: 58px;
      // color: #00242a;
      // letter-spacing: 0.6px;
      // opacity: 1;
      // margin-top: 8px;
      width: 370px;
      height: 36px;
      font-size: 36px;
      font-weight: bold;
      line-height: 0px;
      color: #00242A;
      opacity: 1;
    }
  }
}
.footer {
  width: 100%;
}
.sj-news-list {
  margin-top: 51px;
  padding: 0px 41px;
  box-sizing: border-box;
  img {
    cursor: pointer;
    width: 350px;
    height: 364px;
  }
  img:nth-child(2) {
    margin-top: 19px;
  }
  img:hover {
    box-shadow: 0px 0px 10px #dfdfdf;
    transform: translateY(-5px);
  }
  // img+img{
  //   margin-left: 32px;
  // }
}
.about-us-img {
  width: 100%;
  height: auto;
  position: relative;
  transform: scale(1.2);
  img {
    width: 100%;
    height: auto;
  }
  .cont_img {
    transform: translateY(-500px) scale(0.3);
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -229px;
    margin-top: -49px;
    width: 458px;
    height: 98px;
    opacity: 0;
  }
}
.yyly {
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  // justify-content: center;
  margin-top: 80px;
  // padding-bottom: 128px;
  // margin-bottom: 50px;
  img:first-child {
    width: 416px;
    height: 918px;
  }
  position: relative;
  .yylyfiexd {
    width: 766px;
    height: 1000px;
    margin-left: 16px;
    // background-attachment: fixed;
    background-size: 100% 1376px;
    background-repeat: repeat;
    background-position: center center;
    background-image: url("../assets/image/yyly3333.png");
    // background-position-y: 219.37%;
  }
  .positionbg {
    // width: 1711px !important;
    // height: auto !important;
    // position: absolute;
    // right: 40%;
    // top: -235px;
    // transform: translateX(100%);
  }
}
.yyly2 {
  width: 100%;
  height: auto;
  display: flex;
  // justify-content: center;
  margin-top: 80px;
  padding-bottom: 50px;
  margin-bottom: 50px;
  overflow: hidden;
  img {
    width: 415px;
    height: 918px;
  }
  position: relative;
  .yylyfiexd2 {
    margin-right: 95px;
    width: 690px;
    height: 1200px;
    // background-attachment: fixed;
    background-size: 100% 1648px;
    background-repeat: repeat;
    background-position: center center;
    background-image: url("../assets/image/yyly5555.png");
    // background-position-y: 219.37%;
    margin-top: -150px;
  }
  .positionbg {
    // width: 1711px !important;
    // height: auto !important;
    // position: absolute;
    // right: 40%;
    // top: -235px;
    // transform: translateX(100%);
  }
}
.stories-block-para {
  width: 100%;
  height: auto;
  overflow: hidden;
  // /* background-attachment: fixed; */
  // background-size:auto 114%;
  // background-repeat: no-repeat;
  // background-position: 700% -70px;
  // background-image: url('../assets/image/tiny speck@2x.png');
}

#preloadImages {
  width:0px;
  height:0px;
  display:inline;
  background-image: url(../assets/images/home/home11-1.png);
  background-image: url(../assets/images/home/home11-2.png);
  background-image: url(../assets/images/home/home11-3.png);
  background-image: url(../assets/images/home/home11.png);
  background-image: url(../assets/images/home/home12.png);
  background-image: url(../assets/images/home/home13.png);
}
</style>
