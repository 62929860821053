<template>
  <header class="header" :class="{'isfixed': isfixed,'params':para}">
        <div>
          <img class="logo" src="../assets/image/组 73@2x.png" alt="" />
          <div>

            <div class="text" :class="active==0?'choose':''" @click="goto('/en/')">Home</div>
            <div class="text" :class="active==1?'choose':''" @click="goto('/en/technology')">Technology</div>
            <div class="text" :class="active==2?'choose':''" @click="goto('/en/solutions')">Solutions</div>
            <div class="text" :class="active==3?'choose':''" @click="goto('/en/goods')">Products</div>
            <div class="text" :class="active==4?'choose':''" @click="goto('/en/news')">News</div>
            <div class="text" :class="active==5?'choose':''" >Community</div>
            <div class="text" :class="active==6?'choose':''" @click="goto('/en/about-us')">About us</div>
            <div class="text" :class="active==7?'choose':''" @click="goto('/en/join-us')">Cooperation</div>
            <div class="text" :class="active==8?'choose':''" @click="goto('/en/dev-kit')">Dev Kit</div>
            <img src="../assets/image/flag.png" class="circle"></img>
            <div class="text" @click="jump">CN</div>
            <img class="search" src="../assets/image/搜索@2x11.png" alt="">
          </div>
        </div>
      </header>
</template>

<script>
export default {
  data(){
    return{
      isfixed: false,
    }
  },
  props:['para','active'],
  methods:{
    jump() {
      let curRou  = this.$router.history.current.fullPath;
      if(curRou.startsWith("/en/")) {
        this.$router.push(curRou.replace('/en/', '/'));
      } else {
        this.$router.push(curRou.replace(0, '/en/'));
      }
    },
    goto(path){
      this.$router.push(path);
      scrollTo(0,0)
    },
    scrollToTop() {
      const scrollTop = window.pageYOffset
        || document.documentElement.scrollTop || document.body.scrollTop;

        // console.log(scrollTop >= 30);
      if (scrollTop >= 30) {
        this.isfixed = true;
      } else {
        this.isfixed = false;
      }
    }
  },
  mounted() {
    window.addEventListener('scroll', this.scrollToTop);
  },
}
</script>

<style scoped lang="less">
.header{
  // background-color: rgba(0, 0, 0, 0);
  background-color: #fff;
  top:0px;
  color: #00242A;
  position: relative;
  left: 0px;
  z-index: 1000;
  width: 100%;
  padding: 17px 0px;
  width: 100%;
  >div{
    width: 1200px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    >div{
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .logo{
    width: 158px;
    height: 41px;
  }
  .text{
    margin-right: 24px;
    font-size: 14px;
    font-weight: 300;
    color:#00242acc;
    cursor: pointer;
  }
  .choose{
    color: #00B7D0;
    position: relative;
    color:#00B7D0;
    &::after{
      content: '';
      height: 3px;
      position: absolute;
      width: 50%;
      transform: translateX(-50%);
      border-radius: 10px;
      left: 50%;
      bottom: -10px;
      background-color: #00B7D0;
    }
  }
  .circle{
    width: 16px;
    height: 16px;
    border-radius: 100%;
    background-color: #fff;
    /*background-color: #00242A;*/
    border:0;
    margin-right: 8px;
    background-image: url('../assets/image/flag.png');
    background-size: 100% 100%;

  }
  .search{
    width: 16px;
  }
}
.isfixed{
  position: fixed !important;
  top: 0px !important;
  background-color: rgba(255,255,255,0.6) !important;
  backdrop-filter: saturate(115%) blur(20px);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}
.params{
  background-color: rgba(0, 0, 0, 0);
  top: 30px;
  position: absolute;
}
</style>

