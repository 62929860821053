<template>
  <div class="home-cont" style="overflow: hidden">
    <navs :para="false" :active="0"></navs>
    <img class="fbg" src="../assets/images/jj/fbg.jpg" alt="" />
    <div class="swiper-block">
      <swiper style="width: 100%" ref="mySwiper" :options="swiperOptions">
        <swiper-slide>
          <img class="swiperimg" src="../assets/image/en.png" />
        </swiper-slide>
        <swiper-slide>
          <img class="swiperimg" src="../assets/image/banner_en@2x.png" />
        </swiper-slide>
        <swiper-slide>
          <img class="swiperimg" src="../assets/image/en.png" />
        </swiper-slide>
        <swiper-slide>
          <img class="swiperimg" src="../assets/image/banner_en@2x.png" />
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>
    <div id="preloadedImages" style="width:0; height:0"></div>
    <div class="title" style="padding-bottom: 80px; padding-top: 140px">
      <p>ABOUT US</p>
    </div>
    <div class="about_text">
      <div>SynSense is the world's leading supplier of neuromorphic intelligence and application solutions.
       </div>
       <div>It provides the intelligent application solutions that combine neuromorphic sensing and computing based
       </div>
       <div>
         on the R&D experience of University of Zürich and ETH  Zürich.
       </div>
      <div> SynSense is focusing on edge computing, and will build the cognition ecology of intelligent connectivity.</div>
    </div>
    <div class="title" style="padding-bottom: 80px;margin-top: 340px;padding-top: 140px;">
      <p>APPLICATIONS</p>
    </div>
    <div class="s_out">
      <div class="success">
        <swiper :options="swiperOptions3">
            <swiper-slide>
            <div class="img">
              <img src="../assets/images/jj/02-1.png" alt="" />
            </div>
            <div class="text">
              <div>
                Robot applications have insufficient performance and functional limitations of perception, power consumption, real-time performance and intelligence.
                <br />
                SynSense’s technology focuses on the robot chips research and development, using neuromorphic intelligence to provide the full-custom and complete solutions.
              </div>
              <div class="item">
                <div>
                  <img src="../assets/images/jj/dgh@2x.png" alt="" />
                  <div>
                    <div class="t4">Ultra-low power</div>
                  </div>
                </div>
                <div>
                  <img src="../assets/images/jj/dyc@2x.png" alt="" />
                  <div>
                    <div class="t4">Ultra-low latency</div>
                  </div>
                </div>
              </div>

            </div>
          </swiper-slide>
            <swiper-slide>

            <div class="text">
              <div>
                SynSense's event-driven neuromorphic processor has the world's leading dynamic vision processing technology and paves the path for object detection, tracking and surveillance, which enables event-driven, ultra-low power, always-on and privacy-preserving smart security systems.
              </div>
              <div class="item">
                <div>
                  <img src="../assets/images/jj/dgh@2x.png" alt="" />
                  <div>
                    <div class="t4">Ultra-low power</div>
                  </div>
                </div>
                <div>
                  <img src="../assets/images/jj/ysaq.png" alt="" />
                  <div>
                    <div class="t4">Privacy security</div>
                  </div>
                </div>
              </div>

            </div>
            <div class="img">
              <img src="../assets\images\jj/02-2.png" alt="" />
            </div>
        </swiper-slide>

            <swiper-slide>
            <div class="img">
              <img src="../assets/images/jj/02-3.png" alt="" />
            </div>
            <div class="text">
              <div>
                Existing care systems and devices have problems on complex operation, high power consumption, insufficient intelligence, and privacy concern.
                <br />
                SynSense's neuromorphic intelligent solutions provide milliwatt-level dynamic visual interaction, which can realize fall monitoring, real-time monitoring, and empower a smarter and safer  care system.
              </div>
              <div class="item">
                <div>
                  <img src="../assets/images/jj/dyc@2x.png" alt="" />
                  <div>
                    <div class="t4">Ultra-low latency</div>
                  </div>
                </div>
                <div>
                  <img src="../assets/images/jj/dgh@2x.png" alt="" />
                  <div>
                    <div class="t4">Ultra-low power</div>
                  </div>
                </div>
                <div>
                  <img src="../assets/images/jj/ysaq.png" alt="" />
                  <div>
                    <div class="t4">Privacy security</div>
                  </div>
                </div>
              </div>

            </div>
          </swiper-slide>

          <swiper-slide>
            <div class="text">
              <div>
                Aiming at the improving of low integration, high replacement cost, single monitoring function, and resource occupation of existing animal wearable devices.
                <br />
                SynSense's end-to-end smart animal wearable solution has the advantages of ultra-low power consumption, low cost, high integration and small size.
                <br />
                With powerful biological signal recognition and behavior monitoring functions, it can accurately respond to the needs of smart farming.
              </div>
              <div class="item">
                <div>
                  <img src="../assets/images/jj/dgh@2x.png" alt="" />
                  <div>
                    <div class="t4">Ultra-low power</div>
                  </div>
                </div>
                <div>
                  <img src="../assets/images/jj/dcb.png" alt="" />
                  <div>
                    <div class="t4" style="width:40px;">Low cost</div>
                  </div>
                </div>
                <div>
                  <img src="../assets/images/jj/dyc@2x.png" alt="" />
                  <div>
                    <div class="t4">Ultra-low latency</div>
                  </div>
                </div>
              </div>

            </div>
            <div class="img">
              <img src="../assets\images\jj/02-4.png" alt="" />
            </div>
        </swiper-slide>


        <swiper-slide>
            <div class="img">
              <img src="../assets/images/jj/02-5.png" alt="" />
            </div>
            <div class="text">
              <div>
                Traditional solutions may have problems such as high power consumption, high cost, response delay, privacy concern and so on.
                <br />
                The neuromorphic solution that integrated sensing and computing fuctions  will realize driving behavior detection on the basis of reducing cost and power consumption and protecting privacy.
                <br />
                High-speed obstacle avoidance based on dynamic visual sending and computing can help improve the accuracy of self driving.
              </div>
              <div class="item">
                <div>
                  <img src="../assets/images/jj/dcb.png" alt="" />
                  <div>
                    <div class="t4"  style="width:40px">Low cost</div>
                  </div>
                </div>

                <div>
                  <img src="../assets/images/jj/dyc@2x.png" alt="" />
                  <div>
                    <div class="t4">Ultra-low latency</div>
                  </div>
                </div>
                <div>
                  <img src="../assets/images/jj/ysaq.png" alt="" />
                  <div>
                    <div class="t4">Privacy security</div>
                  </div>
                </div>

              </div>

            </div>
          </swiper-slide>


          <swiper-slide>
            <div class="text">
              <div>
                Facing the weaknesses of traditional smart toys, such as high cost, low agility, low accuracy, low level of intelligent interaction, and privacy exposure, by using neuromorphic intelligence to achieves real-time visual interaction and sound detection at low cost with ultra-low power consumption and ultra-low latency.
                <br />
                SynSense can make toys smarter and have a better interaction with kids.
              </div>
              <div class="item">
                <div>
                  <img src="../assets/images/jj/dcb.png" alt="" />
                  <div>
                    <div class="t4" style="width:40px;">Low cost</div>
                  </div>
                </div>
                <div>
                  <img src="../assets/images/jj/dyc@2x.png" alt="" />
                  <div>
                    <div class="t4">Ultra-low latency</div>
                  </div>
                </div>
                <div>
                  <img src="../assets/images/jj/ysaq.png" alt="" />
                  <div>
                    <div class="t4">Privacy security</div>
                  </div>
                </div>
              </div>

            </div>
            <div class="img">
              <img src="../assets/images/jj/02-6.png" alt="" />
            </div>
        </swiper-slide>

        <swiper-slide>
            <div class="img">
              <img src="../assets/images/jj/02-7.png" alt="" />
            </div>
            <div class="text">
              <div>
                Technology creates more convenient, more comfortable and safer living space for human beings
                <br />
                SynSense deeply integrates ultra-low power consumption, always-on, privacy-protecting intelligent recognition and real-time interaction functions into life scenarios, and take use of neuromorphic intelligence tobuild a more humanized home space.
              </div>
              <div class="item">
                <div>
                  <img src="../assets/images/jj/dgh@2x.png" alt="" />
                  <div>
                    <div class="t4">Ultra-low power</div>
                  </div>
                </div>
                <div>
                  <img src="../assets/images/jj/ysaq.png" alt="" />
                  <div>
                    <div class="t4">Privacy security</div>
                  </div>
                </div>

              </div>

            </div>
          </swiper-slide>


        </swiper>
      </div>
    </div>

    <div class="title" style="padding-bottom: 100px;">
<!--      <p style="letter-spacing: 5px">SOLUTIONS</p>-->
      <p>SOLUTIONS</p>
    </div>
    <ul class="features-list"  style="z-index:10;">
      <li class='item1'>
        <!-- <img class="basemap" src="../assets/images/home/home1.png" alt="" /> -->
         <img class="basemap" style="width: 0px" src="../assets/images/home/home11-1.png" alt="" />
        <div class="mask">
          <p>Smart Vision Processing</p>
          <p>
            SynSense is developing dedicated event-driven neuromorphic processors for real-time vision processing.
          </p>
        </div>
        <div class="search-block" @click="jump">
<!--          <img class="searchIcon " src="../assets/image/搜索@2x.png" alt="" />-->
          Learn More
        </div>
      </li>
      <li class='item2'>
        <!-- <img class="basemap" src="../assets/images/home/home1-2.png" alt="" /> -->
        <div class="mask">
          <p>Auditory processing</p>
          <p>
            SynSense is working on ultra-low-power always-on key-word and command detection based on Spiking Neural Networks (SNNs).
          </p>
        </div>
        <div class="search-block" @click="jump">
<!--          <img class="searchIcon" src="../assets/image/搜索@2x.png" alt="" />-->
          Learn More
        </div>

      </li>
      <!-- <li>
        <img class="basemap" src="../assets/image/10@2x.png" alt="" />
        <div class="mask">
          <p>智能服务助手</p>
          <p>
            SynSense时识科技开发基于脉冲神经网络（SNN）的低功耗的语音识别与口令识别。
          </p>
        </div>
        <div class="search-block">
          <img class="searchIcon" src="../assets/image/搜索@2x.png" alt="" />
        </div>
      </li> -->
      <li  class='item3'>
        <!-- <img class="basemap" src="../assets/images/home/home1-3.png" alt="" /> -->
        <div class="mask">
<!--          <p>WEARABLE DEVICES FOR HEALTH MONITORING</p>-->
          <p>Wearable Devices For Health Monitoring</p>
          <p>SynSense is developing hardware solutions for compact neuromorphic spiking resorvior computing.</p>
        </div>
        <div class="search-block" @click="jump">
<!--          <img class="searchIcon" src="../assets/image/搜索@2x.png" alt="" />-->
<!--          <div class="btnmore">-->
<!--            <span>查看全部项目</span>-->
<!--          </div>-->
          Learn More
        </div>
      </li>
    </ul>
<!--    <div class="btnmore_out">-->
<!--      <div class="btnmore">-->
<!--        <span>查看全部项目</span>-->
<!--      </div>-->
<!--    </div>-->

    <div class="news-block">
      <div class="news-block-cont">
        <div class="title" style="text-align: center">
<!--          <p style="letter-spacing: 5px">LATEST NEWS</p>-->
          <p>LATEST NEWS</p>
        </div>
        <ul class="news-list clearfix">
          <swiper style="width: 100%" ref="mySwiper5" :options="swiperOptions5">
            <swiper-slide>
               <li @click="goDetial(10)">
            <div class="img-block">
              <img
                src="../assets/image/zx1-en.png"
                alt=""
              />
              <div></div>
            </div>
            <p>SynSense taking an active role in development of open source project Tonic: 21Q4</p>
            <p>2021.11.36</p>
          </li>
            </swiper-slide>
          <swiper-slide>
            <li @click="goDetial(11)">
            <div class="img-block">
              <img src="../assets/image/zx2-en.png" alt="" />
              <div></div>
            </div>
            <p>Europe spends €3 million in neuromorphic R&D around SynSense</p>
            <p>2021.5.8</p>
          </li>
          </swiper-slide>
          <swiper-slide>
            <li @click="goDetial(12)">
            <div class="img-block">
              <img
                src="../assets/image/zx3-en.png"
                alt=""
              />
              <div></div>
            </div>
            <p>
              SynSense applies their technology to bi-directional brain implants
            </p>
            <p>2020.9.22</p>
          </li>
          </swiper-slide>
          </swiper>
        </ul>
        <!-- <div class="news-list sj-news-list">
          <img src="../assets/image/新闻01@2x.png">
          <img src="../assets/image/新闻02@2x.png">
          <img src="../assets/image/新闻03@2x.png">
        </div> -->
      </div>
    </div>
    <div class="Partner">
      <div class="title">
<!--        <p style="letter-spacing: 5px">PARTNERS</p>-->
        <p>PARTNERS</p>
      </div>
      <!-- <div class="imgs">
        <img src="../assets/image/01-彩色@2x.png" alt="">
        <img src="../assets/image/02-彩色@2x.png" alt="">
        <img src="../assets/image/03-彩色@2x.png" alt="">
        <img src="../assets/image/04-彩色@2x.png" alt="">
      </div> -->
      <div class="imgs">
        <swiper style="width: 100%" ref="mySwiper" :options="swiperOptions6">
          <swiper-slide>
            <img src="../assets/images/cooper/1-1@2x.png" alt="" />
          </swiper-slide>
          <swiper-slide>
            <img src="../assets/images/cooper/1-2@2x.png" alt="" />
          </swiper-slide>
          <swiper-slide>
            <img src="../assets/images/cooper/1-3@2x.png" alt="" />
          </swiper-slide>
          <swiper-slide>
            <img src="../assets/images/cooper/1-4@2x.png" alt="" />
          </swiper-slide>
          <swiper-slide>
            <img src="../assets/images/cooper/1-5@2x.png" alt="" />
          </swiper-slide>
          <swiper-slide>
            <img src="../assets/images/cooper/1-6@2x.png" alt="" />
          </swiper-slide>
          <swiper-slide>
            <img src="../assets/images/cooper/1-7@2x.png" alt="" />
          </swiper-slide>
          <swiper-slide>
            <img src="../assets/images/cooper/1-8@2x.png" alt="" />
          </swiper-slide>
          <swiper-slide>
            <img src="../assets/images/cooper/1-9@2x.png" alt="" />
          </swiper-slide>
          <swiper-slide>
            <img src="../assets/images/cooper/1-10@2x.png" alt="" />
          </swiper-slide>
        </swiper>
        <!-- <swiper style="width: 100%" ref="mySwiper" :options="swiperOptions1">
          <swiper-slide>
            <img src="../assets/image/logo01@2x.png" alt="" />
          </swiper-slide>
          <swiper-slide>
            <img src="../assets/image/logo02@2x.png" alt="" />
          </swiper-slide>
          <swiper-slide>
            <img src="../assets/image/logo03@2x.png" alt="" />
          </swiper-slide>
          <swiper-slide>
            <img src="../assets/image/logo04@2x.png" alt="" />
          </swiper-slide>
          <swiper-slide>
            <img src="../assets/image/logo05@2x.png" alt="" />
          </swiper-slide>
          <swiper-slide>
            <img src="../assets/image/logo01@2x.png" alt="" />
          </swiper-slide>
          <swiper-slide>
            <img src="../assets/image/logo02@2x.png" alt="" />
          </swiper-slide>
        </swiper> -->
      </div>
    </div>
    <div class="achievement-block-para">
      <div class="achievement-block">
        <div class="wz" style="margin-top:160px;">
          <div>SUBSCRIBE<br> TO LATEST NEWS</div>
          <!-- <div>SUBSCRIBE</div> -->
        </div>
        <div class="sr">
          <input type="text" placeholder="Email" />
          <img src="../assets/image/发送en@2x.png" alt="" />
        </div>
      </div>
    </div>

    <bases></bases>
  </div>
</template>
<script>
import navs from "@/components/navsen.vue";
import bases from "@/components/basesen.vue";
export default {
  name: "Home",
  components: {
    navs,
    bases,
  },
  data() {
    return {
      img1:false,
      tablelistIndex: 0,
      swiperOptions: {
        loop: true, // 循环切换
        // grabCursor: true, //设置为true时，鼠标覆盖Swiper时指针会变成手掌形状，拖动时指针会变成抓手形状
        // setWrapperSize: true, //Swiper使用flexbox布局(display: flex)，
        // 开启这个设定会在Wrapper上添加等于slides相加的宽或高，
        // 在对flexbox布局的支持不是很好的浏览器中可能需要用到。
        autoHeight: false, // 自动高度。设置为true时，wrapper和container会随着当前slide的高度而发生变化。
        autoplay: {
          disableOnInteraction: false,
        }, // 可选选项，自动滑动

        observeParents: false, // 当改变swiper的样式（例如隐藏/显示）或者修改swiper的子元素时，自动初始化swiper
        pagination: {
          el: ".swiper-pagination",
          clickable :true,
        },
        navigation: {
          nextEl: ".rightIcon",
          prevEl: ".leftIcon",
        },
        speed: 300,
        // on: {
        //   // 监听滑动切换事件，返回swiper对象
        //   slideChange: () => {
        //     const i = this.swiper.activeIndex
        //     this.changepicture(i)
        //   }
        // }
        // Some Swiper option/callback...
      },

      swiperOptions3:{
        loop: true, // 循环切换
        // grabCursor: true, //设置为true时，鼠标覆盖Swiper时指针会变成手掌形状，拖动时指针会变成抓手形状
        // setWrapperSize: true, //Swiper使用flexbox布局(display: flex)，
        // 开启这个设定会在Wrapper上添加等于slides相加的宽或高，
        // 在对flexbox布局的支持不是很好的浏览器中可能需要用到。
        autoHeight: false, // 自动高度。设置为true时，wrapper和container会随着当前slide的高度而发生变化。
        autoplay: true, // 可选选项，自动滑动
        observeParents: false, // 当改变swiper的样式（例如隐藏/显示）或者修改swiper的子元素时，自动初始化swiper
        navigation: {
          nextEl: ".rightIcon",
          prevEl: ".leftIcon",
        },
        speed: 300,
      },
      swiperOptions1: {
        slidesPerView: 4,
        loop: true, // 循环切换
        // grabCursor: true, //设置为true时，鼠标覆盖Swiper时指针会变成手掌形状，拖动时指针会变成抓手形状
        // setWrapperSize: true, //Swiper使用flexbox布局(display: flex)，
        // 开启这个设定会在Wrapper上添加等于slides相加的宽或高，
        // 在对flexbox布局的支持不是很好的浏览器中可能需要用到。
        autoHeight: false, // 自动高度。设置为true时，wrapper和container会随着当前slide的高度而发生变化。
        autoplay: true, // 可选选项，自动滑动
        observeParents: false, // 当改变swiper的样式（例如隐藏/显示）或者修改swiper的子元素时，自动初始化swiper
        speed: 1000,
      },
      swiperOptions5: {
        slidesPerView: 3,
        loop: true, // 循环切换
        // grabCursor: true, //设置为true时，鼠标覆盖Swiper时指针会变成手掌形状，拖动时指针会变成抓手形状
        // setWrapperSize: true, //Swiper使用flexbox布局(display: flex)，
        // 开启这个设定会在Wrapper上添加等于slides相加的宽或高，
        // 在对flexbox布局的支持不是很好的浏览器中可能需要用到。
        autoHeight: false, // 自动高度。设置为true时，wrapper和container会随着当前slide的高度而发生变化。
        autoplay: true, // 可选选项，自动滑动
        observeParents: false, // 当改变swiper的样式（例如隐藏/显示）或者修改swiper的子元素时，自动初始化swiper
        speed: 1000,
      },
      swiperOptions6: {
        slidesPerView: 4,
        loop: true, // 循环切换
        // grabCursor: true, //设置为true时，鼠标覆盖Swiper时指针会变成手掌形状，拖动时指针会变成抓手形状
        // setWrapperSize: true, //Swiper使用flexbox布局(display: flex)，
        // 开启这个设定会在Wrapper上添加等于slides相加的宽或高，
        // 在对flexbox布局的支持不是很好的浏览器中可能需要用到。
        autoHeight: false, // 自动高度。设置为true时，wrapper和container会随着当前slide的高度而发生变化。
        autoplay: true, // 可选选项，自动滑动
        observeParents: false, // 当改变swiper的样式（例如隐藏/显示）或者修改swiper的子元素时，自动初始化swiper
        speed: 1000,
      },
    };
  },
  mounted() {
    window.addEventListener("scroll", this.scrollToTop);

    // const that = this;
    // window.onresize = () => (() => {
    //   that.setBGblock();
    // })();
    // this.setBGblock();
  },
  methods: {
    mouseEnter() {
      console.log(this.$refs);
      console.log(this.swiperOptions.autoplay);
      this.swiperOptions.autoplay = false;
      this.$refs.mySwiper.swiper.autoplay.stop();
    },
    mouseLeave() {
      console.log(2);
      this.$refs.mySwiper.swiper.autoplay.start();
    },
    jump() {
      console.log('@@@jump');
      this.$router.push({
        path:'/en/solutions',
      })
    },
    scrollToTop() {
      var hash = window.location.hash;
      if (hash.split("#/").length <= 1 || hash.split("#/")[1] == "") {
        const scrollTop =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop;
        // if (scrollTop >= 30) {
        //   this.isfixed = true;
        // } else {
        //   this.isfixed = false;
        // }

        if(document.getElementsByClassName("about-us-img").length>0){
          let top = document.getElementsByClassName("about-us-img")[0].offsetTop - 95;
            let top2 = top - 300;
          if (scrollTop > top2 && scrollTop <= top) {
            let num = top - scrollTop;
            document.getElementsByClassName("about-us-img")[0].style.transform =
              "scale(" + Number((1 + (num / 300) * 0.3).toFixed(2)) + ")";
            // document.getElementsByClassName('cont_img')[0].style.opacity = 1-(num / 300);
          }
           if (scrollTop >= top) {
              document.getElementsByClassName("about-us-img")[0].style.transform =
                "scale(1)";
              // document.getElementsByClassName('cont_img')[0].style.opacity = 1;
              document.getElementsByClassName("cont_img")[0].style.transform =
                "translateY(0px) scale(1)";
              document.getElementsByClassName("cont_img")[1].style.transform =
                "translateY(0px) scale(1)";
            }
        }


        // let yy = document.getElementsByClassName("yyly")[0].offsetTop - 900;
        // let yy2 = yy + 1000 + 900;
        // let yynum = yy2 - scrollTop;
        // if (scrollTop >= yy && scrollTop <= yy2) {
        //   document.getElementsByClassName(
        //     "yylyfiexd"
        //   )[0].style.backgroundPositionY =
        //     Number(((yynum / 1000) * -300).toFixed(2)) + "px";
        //   // console.log(Number((((yynum / 3050)*50)+20).toFixed(2)))
        // }
        // let yyly = document.getElementsByClassName("yyly2")[0].offsetTop - 900;
        // let yyly2 = yyly + 1200;
        // let yylynum = yyly2 - scrollTop;
        // if (scrollTop >= yyly && scrollTop <= yyly2) {
        //   document.getElementsByClassName(
        //     "yylyfiexd2"
        //   )[0].style.backgroundPositionY =
        //     Number(((yylynum / 1200) * -600).toFixed(2)) + "px";
        //   // console.log(Number((((yynum / 3050)*50)+20).toFixed(2)))
        // }

        // let wztop =
        //   document.getElementsByClassName("about-us-img")[0].offsetTop - 95;
        // let opc = document.getElementsByClassName("texts")[0].offsetTop - 95;
        // let wztop2 = wztop - 500;
        // if (scrollTop > wztop2 && scrollTop >= opc) {
        //   document.getElementsByClassName("cont_img")[0].style.opacity = 1;
        //   document.getElementsByClassName("cont_img")[1].style.opacity = 0;
        // } else {
        //   document.getElementsByClassName("cont_img")[0].style.opacity = 0;
        //   document.getElementsByClassName("cont_img")[1].style.opacity = 0;
        // }
        // if (scrollTop > wztop2 && scrollTop <= wztop) {
        //   let num = wztop - scrollTop;
        //   let dax = Number((1 - num / 500).toFixed(2));
        //   let opc = Number((1 - num / 500).toFixed(2));
        //   let opc1 = Number((num / 500).toFixed(2));
        //   if (dax <= 0.4) {
        //     dax = 0.4;
        //   }
        //   document.getElementsByClassName("cont_img")[0].style.transform =
        //     "translateY(" + Number(-num) + "px) scale(" + dax + ")";
        //   document.getElementsByClassName("cont_img")[1].style.transform =
        //     "translateY(" + Number(-num) + "px) scale(" + dax + ")";
        //   document.getElementsByClassName("cont_img")[0].style.opacity = opc;
        //   document.getElementsByClassName("cont_img")[1].style.opacity = opc1;
        //   // console.log( 'translateY('+Number(-num)+'px) scale('+dax+')')
        // }
      }
    },
    setBGblock() {
      this.$refs.bgBlcok.style.width = `${
        380 + this.$refs.aboutUsCont.getBoundingClientRect().left
      }px`;
    },
    goDetial(item_id){
      console.log('详情id: ',item_id);
      this.$router.push('mediaDetail?id=' + item_id)
      
    }
  },
};
</script>

<style lang="less" scoped>
.item1{
  background-image: url('../assets/images/home/home11-1.png');
  background-size: 100% 100%;
}
.item1:hover{
  z-index: 99;
  background-image: url('../assets/images/home/home11.png');
  background-size: 100% 100%;
}
.item2{
  background-image: url('../assets/images/home/home11-2.png');
  background-size: 100% 100%;
}
.item2:hover{
  z-index: 99;
  background-image: url('../assets/images/home/home12.png');
  background-size: 100% 100%;
}
.item3{
  background-image: url('../assets/images/home/home11-3.png');
  background-size: 100% 100%;
}
.item3:hover{
  z-index: 99;
  background-image: url('../assets/images/home/home13.png');
  background-size: 100% 100%;
}
.success {
  width: 960px;
  margin: 0 auto;
  padding-bottom: 140px;
  .swiper-wrapper > div {
    width: 100% !important;
    height: 312px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    text-align: center;
    .text {
      flex: 1;
      display:flex;
      flex-flow:column;
      justify-content: space-between;
      padding-left:15px;
      padding-right:15px;
      padding-top:14px;
      width: 375px;
      font-size: 14px;
      opacity: 1;
      line-height: 24px;
      color: #003F52;
      .head {
        font-size: 40px;
        font-weight: bold;
        color: #00242a;
        margin-top: 55px;
        margin-bottom: 66px;
      }
      .item {
        display: flex;
        justify-content: space-around;
        font-size: 14px;
        font-weight: 300;
        line-height: 20px;
        height:86px;
        color: #94c47e;
        .t1 {
          font-size: 20px;
          font-weight: bold;
          color: #708065;
        }
        .t11 {
          font-size: 14px;
          font-weight: 300;
          color: #94c47e;
        }
        .t2 {
          margin-top:14px;
          height: 16px;
          font-size: 16px;
          font-weight: 400;
          line-height: 0px;
          color: #00B7D0;
          opacity: 1;
        }
        .t22 {
          font-size: 14px;
          font-weight: 300;
          color: #cec9a2;
        }
        .t3 {
          font-size: 20px;
          font-weight: bold;
          color: #b85546;
        }
        .t33 {
          font-size: 14px;
          font-weight: 300;
          color: #f37442;
        }
        .t4 {
          // margin-top:14px;
          // height: 16px;
          // font-size: 16px;
          // font-weight: 400;
          // line-height: 0px;
          // color: #00B7D0;
          // opacity: 1;
          width: 92px;
          height: 37px;
          font-size: 14px;
          font-weight: normal;
          line-height: 22px;
          color: #00B7D0;
          opacity: 1;
          text-transform:uppercase;
        }
        .t44 {
          font-size: 14px;
          font-weight: 300;
          color: #00a0da;
        }
        > div {
          display: flex;
          flex-flow: column;
          height:70px;
          justify-content: space-between;
          align-items: center;
          > div:nth-child(2) {
            padding:0;
          }
        }
        img {
          width: 40px;
          height: 40px;
        }
      }
    }
    .img {
      width: 440px;
      height: 312px;
      img {
        width: 440px;
        height: 312px;
      }
    }
  }
}
// .success {
//   width: 960px;
//   margin: 0 auto;
//   padding-bottom: 180px;
//   .swiper-wrapper > div {
//     width: 100% !important;
//     height: 260px;
//     display: flex;
//     justify-content: space-between;
//     flex-direction: row;
//     text-align: center;
//     .text {
//       flex: 1;
//       font-size: 16px;
//       font-weight: 300;
//       line-height: 36px;
//       color: #17181a;
//       .head {
//         font-size: 40px;
//         font-weight: bold;
//         color: #00242a;
//         margin-top: 55px;
//         margin-bottom: 66px;
//       }
//       .item {
//         display: flex;
//         justify-content: space-around;
//         font-size: 14px;
//         font-weight: 300;
//         line-height: 20px;
//         color: #94c47e;
//         margin-bottom: 45px;
//         .t1 {
//           font-size: 20px;
//           font-weight: bold;
//           color: #708065;
//         }
//         .t11 {
//           font-size: 14px;
//           font-weight: 300;
//           color: #94c47e;
//         }
//         .t2 {
//           font-size: 20px;
//           font-weight: bold;
//           color: #fed77d;
//         }
//         .t22 {
//           font-size: 14px;
//           font-weight: 300;
//           color: #cec9a2;
//         }
//         .t3 {
//           font-size: 20px;
//           font-weight: bold;
//           color: #b85546;
//         }
//         .t33 {
//           font-size: 14px;
//           font-weight: 300;
//           color: #f37442;
//         }
//         .t4 {
//           font-size: 20px;
//           font-weight: bold;
//           color: #2b5c9d;
//         }
//         .t44 {
//           font-size: 14px;
//           font-weight: 300;
//           color: #00a0da;
//         }
//         > div {
//           display: flex;
//           > div:nth-child(2) {
//             padding-top: 10px;
//             margin-left: 14px;
//           }
//         }
//         img {
//           width: 54px;
//           height: 54px;
//         }
//       }
//     }
//     .img {
//       width: 460px;
//       height: 260px;
//       img {
//         width: 460px;
//         height: 260px;
//       }
//     }
//   }
// }
.about_text {
  text-align: center;
  padding-bottom: 70px;

  font-size: 18px;
  font-weight: normal;
  line-height: 36px;
  color: #003F52;
  opacity: 1;
}
.dx_2 {
  animation: dx 2s linear;
}
.opc_3 {
  animation: opc 3s linear;
}

@keyframes dx {
  0% {
    transform: scale(1.5);
    opacity: 0.8;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes opc {
  0% {
    opacity: 0;
  }
  60% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.swiper-block {
  position: relative;
  .banner-center {
    width: 1200px;
    height: 267px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -600px;
    margin-top: -133.5px;
    z-index: 10;
  }
  .swiperimg {
    width: 100%;
  }
  .swiper-pagination {
    width: 100%;
    bottom: 15%;
    /deep/.swiper-pagination-bullet {
      width: 26px;
      height: 26px;
      background-color: #ffffff;
      opacity: 1;
      margin: 0px 29px;
    }
    /deep/.swiper-pagination-bullet-active {
      background-color: #0086cd;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        width: 40px;
        height: 40px;
        border-radius: 100%;
        background-color: rgba(0, 0, 0, 0);
        box-sizing: border-box;
        border: 4px solid #0086cd;
        transform: translateX(-50%) translateY(-50%);
        left: 50%;
        top: 50%;
      }
    }
  }
}
.title {
  text-align: center;
  p:nth-child(1) {
    height: 40px;
    font-size: 36px;
    font-family: Gotham;
    font-weight: bold;
    line-height: 40px;
    color: #00B7D0;
    opacity: 1;
  }
  p:nth-child(2) {
    color: #00242a;
    font-size: 40px;
    font-weight: bold;
  }
}
.about-us {
  width: 100%;
  background-color: #fff;
  height: auto;
  padding-bottom: 122px;
  overflow: hidden;
  .about-us-cont {
    width: 1200px;
    height: 100%;
    margin: auto;
    display: flex;
    position: relative;
    .about-us-left {
      width: 100%;
      // padding-right: 190px;
      box-sizing: border-box;
      color: #17181a;
      font-size: 18px;
      text-align: center;
      .title {
        text-align: center;
        margin-top: 122px;
        letter-spacing: 5px;
      }
      .texts {
        width: 630px;
        height: 180px;
        font-size: 30px;
        font-weight: 500;
        line-height: 60px;
        color: #17181a;
        opacity: 1;
        margin: 10px auto 0;
      }
      .text1 {
        margin-top: 38px;
      }
      .text2 {
        margin-top: 25px;
      }
      .img1 {
        width: 180px;
        margin-top: 139px;
      }
      .img2 {
        width: 477px;
        margin-top: 69px;
      }
    }
    .bg-blcok {
      position: absolute;
      width: 380px;
      height: 100%;
      left: 820px;
      background-color: rgba(0, 0, 0, 0.8);
      img {
        width: 57%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        left: 50%;
      }
    }
  }
}
.stories-block {
  width: 1200px;
  height: auto;
  margin: auto;
  .title {
    margin-top: 128px;
  }
  .tablelist {
    display: flex;
    justify-content: space-between;
    margin-top: 67px;
    li {
      width: 290px;
      cursor: pointer;
      img {
        width: 100%;
      }
    }
  }
  .cont-img {
    width: 1180px;
    margin: auto;
    margin-top: 91px;
  }
  .iconList {
    display: flex;
    justify-content: space-between;
    margin-top: 70px;
    li {
      text-align: center;
      width: 220px;
      cursor: pointer;
      color: #00242a;
      > div {
        width: 220px;
        height: 220px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 4px dashed #505156;
        border-radius: 100%;
        img {
          width: 90px;
        }
        .img1 {
          display: block;
        }
        .img2 {
          display: none;
        }
      }
      p:nth-child(2) {
        font-size: 38px;
        margin-top: 38px;
        font-weight: bold;
      }
      p:nth-child(3) {
        font-size: 30px;
        margin-top: 5px;
      }
      // &:hover{
      //   >div{
      //     border:none;
      //     background-color: #0086CD;
      //     .img1{
      //       display: none;
      //     }
      //     .img2{
      //       display: block;
      //     }
      //   }
      // }
    }
    .chooseLi {
      > div {
        border: none;
        background-color: #0086cd;
        .img1 {
          display: none;
        }
        .img2 {
          display: block;
        }
      }
    }
  }
}
.features-list {
  background:#4F4F4F;
  height: 700px;
  display: flex;
  li {
    flex: 1;
    position: relative;
    .basemap {
      width: 100%;
    }
    .mask {
      width: 100%;
      height: 100%;
      position: absolute;
      background-color: rgba(0, 0, 0, 0.5);
      left: 0px;
      top: 0px;
      color: #ffffff;
      opacity: 1;
      transition: all 1s;
      p {
        margin: auto;
      }
      p:nth-child(1) {
        width: 382px;
        max-width: 100%;
        // height: 84px;
        text-align: center;
        font-size: 26px;
        margin-top: 290px;
        text-transform: uppercase;
      }
      p:nth-child(2) {
        width: 383px;
        max-width: 90%;
        text-align: center;
        font-size: 16px;
        margin-top: 40px;
        font-weight: 300;
        line-height: 28px;
        color: #FFFFFF;
        opacity: 1;
      }
    }
    .search-block {
      width: 260px;
      height: 66px;
      line-height:66px;
      font-family: "Source Han Sans CN";
      font-size:26px;
      color: #FFFFFF;;
      border-radius: 66px;
      background-color: #00b7d0;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      opacity: 0;
      img {
        width: 50px;
      }
    }
    &:hover {
      .mask {
        opacity: 0;
      }
      .search-block {
        transition: all 1s;
        opacity: 1;
      }
    }
  }
}
// .features-list {
//   height: 860px;
//   display: flex;
//   li {
//     flex: 1;
//     position: relative;
//     .basemap {
//       width: 100%;
//     }
//     .mask {
//       width: 100%;
//       height: 100%;
//       position: absolute;
//       background-color: rgba(0, 0, 0, 0.5);
//       left: 0px;
//       top: 0px;
//       color: #ffffff;
//       opacity: 1;
//       transition: all 1s;
//       p {
//         margin: auto;
//       }
//       p:nth-child(1) {
//         width: 292px;
//         max-width: 100%;
//         height: 84px;
//         text-align: center;
//         font-size: 30px;
//         margin-top: 207px;
//       }
//       p:nth-child(2) {
//         width: 354px;
//         max-width: 90%;
//         text-align: center;
//         font-size: 20px;
//         margin-top: 57px;
//       }
//     }
//     .search-block {
//       width: 260px;
//       height: 66px;
//       line-height:66px;
//       font-family: "Source Han Sans CN";
//       font-size:26px;
//       color: #FFFFFF;;
//       border-radius: 66px;
//       background-color: #00b7d0;
//       position: absolute;
//       left: 50%;
//       top: 50%;
//       transform: translateX(-50%) translateY(-50%);
//       display: flex;
//       align-items: center;
//       justify-content: center;
//       cursor: pointer;
//       opacity: 0;
//       img {
//         width: 50px;
//       }
//     }
//     &:hover {
//       .mask {
//         opacity: 0;
//       }
//       .search-block {
//         transition: all 1s;
//         opacity: 1;
//       }
//     }
//   }
// }
.btnmore_out{
  width: 100%;
  height: auto;
  background-color: #fff;
  margin-top: -70px;
  padding-top: 1px;
}
.btnmore {
  width: 208px;
  height: 54px;
  border: 2px solid #0086cd;
  border-radius: 27px;
  font-size: 22px;
  line-height: 54px;
  text-align: center;
  color: #0086cd;
  margin: 71px auto;
  margin-bottom: 0px;
  cursor: pointer;
  position: relative;
  background-color: transparent;
  overflow: hidden;
  box-sizing: border-box;
  span {
    position: relative;
    z-index: 10;
  }
}
.btnmore:hover {
  border: none;
  line-height: 56px;
  color: #fff;
  animation: 1s color1 linear;
}
.btnmore:hover::before {
  content: "";
  width: 120%;
  height: 100%;
  position: absolute;
  left: -10%;
  top: 0;
  background-color: #0086cd;
  z-index: -1px;
  animation: 1s lrs linear;
}
.btnmore:hover::after {
  content: "";
  width: 120%;
  height: 100%;
  position: absolute;
  left: 110%;
  top: 0;
  background-color: #0086cd;
  z-index: -1px;
  animation: 1s lrs2 linear;
}
@keyframes lrs {
  0% {
    left: -120%;
  }
  100% {
    left: -10%;
  }
}
@keyframes lrs2 {
  0% {
    left: 100%;
  }
  100% {
    left: -10%;
  }
}
@keyframes color1 {
  0% {
    color: #0086cd;
  }
  100% {
    color: #ffffff;
  }
}
.fbg {
  position: fixed;
  z-index: -1;
  width: 100%;
  height: 100%;
}
.s_out {
  width: 100%;
  height: auto;
  background-color: #fff;
}
.tr {
  width: 100%;
  height: 340px;
  background-color: transparent;
}
.home-cont {
  > div {
    position: relative;
    z-index: 10;
    background-color: #fff;
  }
}
.news-block {
  width: 100%;
  height: auto;
  margin: auto;
  // margin-top: 120px;
  padding-top: 134px;
  padding-bottom: 153px;
  background: #ffffff;
  overflow: hidden;
    .news-block-cont {
    width: 960px;
    margin: auto;
    .title {
      text-align: left;
    }
    .news-list {
      display: flex;
      position: relative;
      left: 0px;
      margin-top: 92px;
      justify-content: space-between;
      li {
        width: 310px;
        height: 320px;
        margin-right: 15px;
        background-color: red;
        flex-shrink: 0;
        border-radius: 10px;
        background-color: #f8f8f8;
        overflow: hidden;
        display: inline-block;
        .img-block {
          position: relative;
          width: 100%;
          height: 180px;
          img {
            width: 100%;
            height: 180px;
          }
          div {
            position: absolute;
            left: 0px;
            top: 0px;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.5);
            opacity: 1;
          }
        }
        &:hover {
          cursor: pointer;
          background-color: #ffffff;
          box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.16);
          .img-block {
            div {
              opacity: 0;
            }
          }
        }
        p {
          padding: 20px 20px;
          padding-bottom: 0px;
        }
        p:nth-child(2) {
          // color: #1d1d1f;
          // margin-top: 25px;
          // word-break: keep-all; /* 不换行 */
          // white-space: nowrap; /* 不换行 */
          // overflow: hidden;
          // text-overflow: ellipsis;

          // width: 262px;
          font-size: 14px;
          font-weight: 400;
          line-height: 26px;
          color: #1D1D1F;
          opacity: 1;
          display:-webkit-box;
          overflow: hidden;
          text-overflow: ellipsis;
          word-wrap: break-word;
          white-space: normal !important;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        p:nth-child(3) {
          color: #6E6E73;
          font-size: 14px;
          // margin-top: 42px;
        }
      }
    }
  }
  // .news-block-cont {
  //   width: 1200px;
  //   margin: auto;
  //   .title {
  //     text-align: left;
  //   }
  //   .news-list {
  //     display: flex;
  //     position: relative;
  //     left: 0px;
  //     margin-top: 92px;
  //     justify-content: space-between;
  //     li {
  //       width: 482px;
  //       height: 400px;
  //       margin-right: 22px;
  //       background-color: red;
  //       flex-shrink: 0;
  //       border-radius: 10px;
  //       background-color: #f8f8f8;
  //       overflow: hidden;
  //       .img-block {
  //         position: relative;
  //         width: 100%;
  //         height: 272px;
  //         img {
  //           width: 100%;
  //           height: 272px;
  //         }
  //         div {
  //           position: absolute;
  //           left: 0px;
  //           top: 0px;
  //           width: 100%;
  //           height: 100%;
  //           background-color: rgba(0, 0, 0, 0.5);
  //           opacity: 1;
  //         }
  //       }
  //       &:hover {
  //         cursor: pointer;
  //         background-color: #ffffff;
  //         box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.16);
  //         .img-block {
  //           div {
  //             opacity: 0;
  //           }
  //         }
  //       }
  //       p {
  //         padding: 0px 20px;
  //       }
  //       p:nth-child(2) {
  //         color: #1d1d1f;
  //         margin-top: 25px;
  //         word-break: keep-all; /* 不换行 */
  //         white-space: nowrap; /* 不换行 */
  //         overflow: hidden;
  //         text-overflow: ellipsis;
  //       }
  //       p:nth-child(3) {
  //         color: #6e6e73;
  //         font-size: 12px;
  //         margin-top: 42px;
  //       }
  //     }
  //   }
  // }
}
.Partner {
  .swiper-slide {
    height:126px;
  }
  width: 100%;
  height: 420px;
  background: #fff;
  padding-top: 140px;
  box-sizing: border-box;
  margin-top: 340px;
    .imgs {
    width: 960px;
    margin: auto;
    margin-top: 116px;
    height: 132px;
    img {
      width: 220px;
      height:114px;
      background: #FFFFFF;
      margin-top:6px;
      margin-left:10px;
      opacity: 1;
      border-radius: 8px;
      box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
      -moz-box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
      -webkit-box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
    }

  }
  // .imgs {
  //   width: 960px;
  //   margin: auto;
  //   margin-top: 116px;
  //   img {
  //     width: 192px;
  //     height:90px;
  //     // margin-left: 46px;
  //   }
  // }
}
.achievement-block-para {
  width: 100%;
  height: auto;
  background: linear-gradient(180deg, #ffffff 0%, #e6e6e6 100%);
}
.achievement-block {
  width: 960px;
  height: auto;
  margin: 0 auto;
  // background-image: url('../assets/image/订阅-底@2x.png');
  // background-repeat: no-repeat;
  // background-size: 100% 100%;
  // padding-top: 114px;
  padding-bottom: 140px;
  box-sizing: border-box;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  > .sr {
    width: 610px;
    height: 62px;
    // padding: 4px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    border-radius: 8px;
    input {
      flex: 1;
      height: 54px;
      line-height: 54px;
      padding: 0px 23px;
      border: none;
      outline: none;
      font-size:18px;

    }
    input::placeholder {
      color: #00B7D0;
    }
    img {
      width: 150px;
      height: 54px;
      margin-right:4px;
    }
  }
  > .wz {
    > div:first-child {
      width: auto;
      height: auto;
      font-size: 20px;
      font-weight: 400;
      line-height: 30px;
      color: #00242a;
      letter-spacing: 5px;
      opacity: 1;
    }
    > div:last-child {
      // font-size: 40px;
      // font-weight: bold;
      // line-height: 58px;
      // color: #00242a;
      // letter-spacing: 0.6px;
      // opacity: 1;
      // margin-top: 8px;
      // width: 370px;
      // height: 36px;
      // font-size: 36px;
      // font-weight: bold;
      // line-height: 0px;
      // color: #00242A;
      // opacity: 1;
      width: 360px;
      height: 69px;
      font-size: 30px;
      font-weight: bold;
      line-height: 36px;
      color: #00B7D0;
      opacity: 1;
    }
  }
}
// .achievement-block {
//   width: 1200px;
//   height: auto;
//   margin: 0 auto;
//   // background-image: url('../assets/image/订阅-底@2x.png');
//   // background-repeat: no-repeat;
//   // background-size: 100% 100%;
//   // padding-top: 114px;
//   padding-bottom: 85px;
//   box-sizing: border-box;
//   display: flex;
//   align-items: flex-end;
//   justify-content: space-between;
//   > .sr {
//     width: 548px;
//     height: 62px;
//     // padding: 4px;
//     background-color: #ffffff;
//     display: flex;
//     align-items: center;
//     border-radius: 8px;
//     margin-left: 178px;
//     input {
//       flex: 1;
//       height: 54px;
//       line-height: 54px;
//       padding: 0px 23px;
//       border: none;
//       font-size:22px;
//     }
//     img {
//       width: 150px;
//       height: 54px;
//     }
//   }
//   > .wz {
//     > div:first-child {
//       width: auto;
//       height: auto;
//       font-size: 20px;
//       font-weight: 400;
//       line-height: 30px;
//       color: #00242a;
//       letter-spacing: 5px;
//       opacity: 1;
//     }
//     > div:last-child {
//       font-size: 40px;
//       font-weight: bold;
//       line-height: 58px;
//       color: #00242a;
//       letter-spacing: 0.6px;
//       opacity: 1;
//       margin-top: 8px;
//     }
//   }
// }
.footer {
  width: 100%;
}
.sj-news-list {
  margin-top: 51px;
  padding: 0px 41px;
  box-sizing: border-box;
  img {
    cursor: pointer;
    width: 350px;
    height: 364px;
  }
  img:nth-child(2) {
    margin-top: 19px;
  }
  img:hover {
    box-shadow: 0px 0px 10px #dfdfdf;
    transform: translateY(-5px);
  }
  // img+img{
  //   margin-left: 32px;
  // }
}
.about-us-img {
  width: 100%;
  height: auto;
  position: relative;
  transform: scale(1.2);
  img {
    width: 100%;
    height: auto;
  }
  .cont_img {
    transform: translateY(-500px) scale(0.3);
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -229px;
    margin-top: -49px;
    width: 458px;
    height: 98px;
    opacity: 0;
  }
}
.yyly {
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  // justify-content: center;
  margin-top: 80px;
  // padding-bottom: 128px;
  // margin-bottom: 50px;
  img:first-child {
    width: 416px;
    height: 918px;
  }
  position: relative;
  .yylyfiexd {
    width: 766px;
    height: 1000px;
    margin-left: 16px;
    // background-attachment: fixed;
    background-size: 100% 1376px;
    background-repeat: repeat;
    background-position: center center;
    background-image: url("../assets/image/yyly3333.png");
    // background-position-y: 219.37%;
  }
  .positionbg {
    // width: 1711px !important;
    // height: auto !important;
    // position: absolute;
    // right: 40%;
    // top: -235px;
    // transform: translateX(100%);
  }
}
.yyly2 {
  width: 100%;
  height: auto;
  display: flex;
  // justify-content: center;
  margin-top: 80px;
  padding-bottom: 50px;
  margin-bottom: 50px;
  overflow: hidden;
  img {
    width: 415px;
    height: 918px;
  }
  position: relative;
  .yylyfiexd2 {
    margin-right: 95px;
    width: 690px;
    height: 1200px;
    // background-attachment: fixed;
    background-size: 100% 1648px;
    background-repeat: repeat;
    background-position: center center;
    background-image: url("../assets/image/yyly5555.png");
    // background-position-y: 219.37%;
    margin-top: -150px;
  }
  .positionbg {
    // width: 1711px !important;
    // height: auto !important;
    // position: absolute;
    // right: 40%;
    // top: -235px;
    // transform: translateX(100%);
  }
}
.stories-block-para {
  width: 100%;
  height: auto;
  overflow: hidden;
  // /* background-attachment: fixed; */
  // background-size:auto 114%;
  // background-repeat: no-repeat;
  // background-position: 700% -70px;
  // background-image: url('../assets/image/tiny speck@2x.png');
}

  #preloadImages {
    width:0px;
    height:0px;
    display:inline;
    background-image: url(../assets/images/home/home11-1.png);
    background-image: url(../assets/images/home/home11-2.png);
    background-image: url(../assets/images/home/home11-3.png);
    background-image: url(../assets/images/home/home11.png);
    background-image: url(../assets/images/home/home12.png);
    background-image: url(../assets/images/home/home13.png);
  }
</style>
