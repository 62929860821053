import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import HomeEn from '../views/HomeEn.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
  {
    path: '/about-us',
    name: 'About_b',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About_b.vue'),
  },
  {
    //技术核心
    path: '/technology',
    name: 'technical',
    component: () => import(/* webpackChunkName: "about" */ '../views/technical.vue'),
  },
  {
    path: '/homePage',
    name: 'homePage',
    component: () => import(/* webpackChunkName: "about" */ '../views/homePage.vue'),
  },
  {
    path: '/news',//媒体中心
    name: 'mediaCenter',
    component: () => import(/* webpackChunkName: "about" */ '../views/mediaCenter.vue'),
  },
  {
    path: '/dev-kit',//申请开发套件
    name: 'exploit',
    component: () => import(/* webpackChunkName: "about" */ '../views/exploit.vue'),
  },
  {
    path: '/goods',//产品矩阵
    name: 'goods',
    component: () => import(/* webpackChunkName: "about" */ '../views/goods.vue'),
  },
  {
    path: '/join-us',//商务合作
    name: 'coop',
    component: () => import(/* webpackChunkName: "about" */ '../views/coop.vue'),
  },
  {
    path: '/solutions',//解决方案
    name: 'solve',
    component: () => import(/* webpackChunkName: "about" */ '../views/solve.vue'),
  },
  {
    path: '/mediaDetail',//解决方案
    name: 'mediaDetail',
    component: () => import(/* webpackChunkName: "about" */ '../views/mediaDetail.vue'),
  },

  {
    path: '/en',
    name:'en',
    component: () => import(/* webpackChunkName: "about" */ '../views/en.vue'),
    redirect:'/en',
    children:[
      {
        path: '',
        name: 'HomeEn',
        component: HomeEn,
      },
      {
        path: 'solutions',//解决方案
        name: 'solveen',
        component: () => import(/* webpackChunkName: "about" */ '../views/solveen.vue'),
      },
      {
        path: 'join-us',//商务合作
        name: 'encoop',
        component: () => import(/* webpackChunkName: "about" */ '../views/coopEn.vue'),
      },
      {
        path: 'goods',//产品矩阵
        name: 'engoods',
        component: () => import(/* webpackChunkName: "about" */ '../views/goodsen.vue'),
      },
      {
        path: 'dev-kit',//申请开发套件
        name: 'enexploit',
        component: () => import(/* webpackChunkName: "about" */ '../views/exploitEn.vue'),
      },
      {
        path: 'news',//媒体中心
        name: 'enmediaCenter',
        component: () => import(/* webpackChunkName: "about" */ '../views/mediaCenterEn.vue'),
      },
      {
        path: 'mediaDetail',//媒体中心
        name: 'mediaDetail',
        component: () => import(/* webpackChunkName: "about" */ '../views/mediaDetailEn.vue'),
      },
      {
        //技术核心
        path: 'technology',
        name: 'entechnical',
        component: () => import(/* webpackChunkName: "about" */ '../views/technicalen.vue'),
      },
      {
        path: 'about-us',
        name: 'enabout-us',
        component: () => import(/* webpackChunkName: "about" */ '../views/About_bEn.vue'),
      },
    ]
  },
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  mode: "history",
  routes,
});

export default router;
