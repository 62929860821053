<template>
  <div class="base_para" style="" >
<!--    <img src="../assets/image/底部配图@2x.png" alt="">-->
    <div class="base">
      <div class="base_t">
<!--        <div>-->
<!--          <img class="logo" src="../assets/images/bases/SynSense logo-02@2x.png" alt="">-->
<!--        </div>-->

        <div class="menu_cont">
          <div>
            <div>解决方案</div>
          </div>
          <div>
            <div>产品矩阵</div>
            <!--          <div>DYNAP</div>-->
            <!--          <div>XYLO</div>-->
            <!--          <div>SPECK</div>-->
          </div>
          <div>
            <div>媒体中心</div>
            <!--          <div>解决方案</div>-->
            <!--          <div>开发套件</div>-->
          </div>
          <div>
            <div>关于我们</div>
            <!--          <div>新闻</div>-->
          </div>
          <div>
            <div>商务合作</div>
            <!--          <div class="me">-->
            <!--            <img src="../assets/images/bases/facebook@2x.png" alt="">-->
            <!--            <img src="../assets/images/bases/推特@2x.png" alt="">-->
            <!--            <img src="../assets/images/bases/相机@2x.png" alt="">-->
            <!--          </div>-->
          </div>
        </div>
        <div>
          <img class="logo" src="../assets/images/bases/SynSense logo-02@2x.png" alt="">
          <div class="me" ref="me">
            <img src="../assets/images/bases/facebook@2x.png" alt="">
            <img src="../assets/images/bases/推特@2x.png" alt="">
            <img src="../assets/images/bases/相机@2x.png" alt="">
          </div>
          <img class="qrcode" src="../assets/images/bases/qr.png" ref="qr">
        </div>
      </div>

      <div class="base_b">
        <div>不接受任何军事用途的开发或投资<span style="margin: 0 20px 0 20px">|</span> 蜀ICP备20018346号</div>
        <div class="erweima" @mouseenter="qrHover" @mouseleave="qrLeave" ref="erweima">
          <img src="../assets/images/bases/wx.png" alt="" ref="qr_e"><span ref="qr_t">时识科技官微</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      isfixed: false,
    }
  },
  methods:{
    qrHover() {
      this.$refs.me.style.display="none";
      this.$refs.qr.style.display="block";
      this.$refs.erweima.style.background="white";
      this.$refs.erweima.style.borderColor="#00B7D0";
      this.$refs.qr_t.style.color="#00B7D0";
      console.log(this.$refs.qr_e);
      this.$refs.qr_e.src=require("../assets/images/bases/qr_b.png");

    },
    qrLeave() {
      this.$refs.me.style.display="flex";
      this.$refs.qr.style.display="none";
      this.$refs.erweima.style.background="black";
      this.$refs.erweima.style.border="1px solid #FFFFFF";
      this.$refs.qr_t.style.color="white";
      this.$refs.qr_e.src=require("../assets/images/bases/wx.png");
    }
  },
  mounted() {

  },
}
</script>

<style scoped lang="less">
.base_para{
  width: 100%;
  height: auto;
  background: url('../assets/image/底部配图@2x.png') no-repeat ;
  background-size: 100% 100%;
  min-height: 360px;

  /*position: relative;*/
  >img{
    width: 100%;
    height: auto;
  }
  .base{
    width: 960px;
    height: 100%;
    min-height: 360px;
    margin: 0 auto;
    padding-top: 111px;
    padding-bottom: 26px;
    box-sizing: border-box;
    /*position: absolute;*/
    top: 0px;
    left: 25%;
    /*margin-left: -600px;*/
    box-sizing: border-box;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
  }
}
.base_t{
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  .menu_cont {
    width:706px; display: flex; flex-flow: row nowrap; justify-content: space-between; align-items: flex-start
  }
  div{
    width: auto;
    height: auto;
    div{
      font-size: 14px;
      line-height: 14px;
      font-weight: 300;
      color: #FFFFFF;
      cursor: pointer;
      margin-left:8px;
    }
    div:first-child{
      color: #00B7D0;
    }
    div+div{
      /*margin-top: 27px;*/
    }
  }
}
.base_b{
  font-size: 14px;
  line-height: 14px;
  font-weight: 300;
  color: #FFFFFF;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  /*letter-spacing: 6px;*/
  .erweima {
    width: 140px;
    height: 40px;
    border: 1px solid #FFFFFF;
    opacity: 1;
    box-sizing: border-box;
    padding-left:14px;
    border-radius: 100px;
    vertical-align: center;

    font-size: 14px;
    font-weight: 300;
    line-height: 40px;
    color: #FFFFFF;
    display:flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    opacity: 1;
    img {
      width:20px;
      height:20px;
      vertical-align: center;
    }
    span {
      padding-left:8px;
    }
  }
}
.logo{
  margin-top:-11px;
  width: 132px;
  height: 36px;
  margin-left:8px;
}
.me{
  width: auto;
  height:36px;
  margin-top: 16px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  /*display:none;*/
  img{
   width: 25px;
   height: 25px;
  }
  img+img{
    margin-left: 22px;
  }
}
  .qrcode {
    margin-top:4px;
    width:140px;
    height:140px;
    box-sizing: border-box;
    display:none;
  }
</style>

